import React, {useEffect, useState, useRef} from 'react';
import Spinner from './helpers/Spinner';
import moment from 'moment';
import { getFixedAssetsRequestList} from '../actions/fixedassetrequestActions';
import {obtenerDepartamentos, obtenerTerritories } from '../actions/manageActions';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import FixedAssetRequests from './FixedAssetRequests';
//import ReactExport from "react-export-excel";
import DatePicker from "react-datepicker";
import exportToCSV from './helpers/ExportOrders';


// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const FixedAssetRequestPanel = ({history}) => {

    const dispatch = useDispatch();

     //acceder al state
     //const userInfo = useSelector(state=>state.manager.employees);
     const departments = useSelector(state=>state.manager.departments);
     const territories = useSelector(state => state.manager.territories);
     const fixedAssetRequests = useSelector(state => state.fixedAsset.fixedAssetRequests);

     const [department, saveDepartment] = useState('');
     const [territory, saveTerritory] = useState('');
     const [requests, setRequests] = useState([]);
     const [dayBegining, saveDayBeginning] = useState('');
     const [dayEnd, saveDayEnd] = useState('');

     const loadFixedAssetRequest = (department, territory, dateReport, fromDate, toDate) => dispatch(getFixedAssetsRequestList(department, territory, dateReport, fromDate, toDate));

    // const filterElementsByName  = (array, query) => {
    //     return array.filter(function(el) {
    //         let name = `${el.employee_name} ${el.employee_lastname}`;
    //        // console.log(name)
    //         return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
    //     })
    //   }

    const handleSearch = async (department, territory, dateReport, fromDate, toDate) => {


        if(department === '' && territory === '' && dayBegining === '' && dayEnd === '') {
            dateReport = 'all';
            await loadFixedAssetRequest(department, territory, 'all', fromDate, toDate);
        } else {

       // console.log(department, territory, dateReport, fromDate, toDate);
        await loadFixedAssetRequest(department, territory, true, fromDate, toDate);
        }
    }

    // const handleReport = async (department, territory, dateReport, fromDate, toDate) => {
    //    // await loadFixedAssetRequest(department, territory, dateReport, fromDate, toDate);
    // }

    const handleExport = async (data) => {
        exportToCSV(data);
    }
    
    // Time Refs
    const dayBeginingRef = useRef('');
    const dayEndRef = useRef('');

    useEffect(() => {
        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();

        // let data = {
        //     reportType:1,
        //     manager: true,
        //     companyManager: 'Mexilink'
        // }
        // const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        // loadEmployeesManager();

        const loadTerritories = ()=> dispatch(obtenerTerritories());
        loadTerritories();

        

    }, [])

    useEffect(() => {

        setRequests(fixedAssetRequests);
      
    }, [fixedAssetRequests])
    
    
    return (
        <>
        <div className='container mt-5'>
            <h1 style={{textAlign:'center'}}>Fixed Asset Request Control Panel</h1>
            <hr/>

            <div className='row'>
                <div className='col-md-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5 className="text-center">Filter By Department</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group text-center">
                                    <label>Department:</label>
                                    <select className="custom-select"
                                    onChange = {e => saveDepartment(e.target.value)}
                                    required
                                    >
                                        <option value="">Select Department...</option>
                                        {departments.map((department, index) =>(
                                            <option key={index} value={department.department}>{department.department}</option>
                                        ))}
                                    </select>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

                <div className='col-md-3'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5 className="text-center">Filter By Territory</h5>
                        </div>
                        <div className='card-body'>
                            <div className="form-group text-center">
                                    <label>Territory:</label>
                                    <select className="custom-select"
                                            onChange = {e => saveTerritory(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Territory...</option>
                                                {territories.map((territory, index) =>(
                                                   <option key={index} value={territory.territory}>{territory.territory}</option>
                                                ))}
                                            </select>
                                </div>
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>

                <div className='col-md-6'>
                    <div className='card'>
                        <div className='card-header'>
                            <h5 className="text-center">Reports</h5>
                        </div>
                        <div className='card-body p-4'>
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>From:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDayBeginning(e.target.value)}
                                        required
                                        ref = {dayBeginingRef}
                                        /> */}
                                        <DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={dayBegining}
                                        onChange={date => saveDayBeginning(date)}
                                        
                                        placeholderText="Custom Date Range"
                                        ref = {dayBeginingRef}
                                    // minDate={new Date()}
                                        
                                        required
                                        >
                                            
                                        </DatePicker>

                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>To:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDayEnd(e.target.value)}
                                        required
                                        ref = {dayEndRef}
                                        /> */}
                                        <DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={dayEnd}
                                        onChange={date => saveDayEnd(date)}
                                        
                                        placeholderText="Custom Date Range"
                                        ref = {dayEndRef}
                                        //minDate={new Date()}
                                       
                                        required
                                        >
                                            
                                        </DatePicker>
                                            
                                    </div>
                                </div>
                                
                                <div className='col-md-12'>
                                    <button className='btn btn-success w-100' onClick={e => handleExport(fixedAssetRequests)}>Export Report</button>
                                
                                {/* <ExcelFile element={<button className="list-group-item list-group-item-action">Download Report</button>}>
                                                <ExcelSheet  data={fixedAssetRequests} name="Employees">
                                                    <ExcelColumn label="Employee Name" value="employee_name"/>
                                                    <ExcelColumn label="Department" value="department"/>
                                                    <ExcelColumn label="Equipment Requested" value={(col) => {
                                                        let equipmentJson = JSON.parse(col.equipment_requested);
                                                        let equiposTemplate = ``;
                                                        equipmentJson.forEach(equipment => {

                                                            equiposTemplate+= `

                                                           Type: 
                                                            ${equipment.tipo},
                                                            Brand:
                                                            ${equipment.brand},
                                                            Cost:
                                                            ${equipment.cost},
                                                            Asset Number:
                                                            ${equipment.assetNumber},
                                                            Serial Number:
                                                            ${equipment.serial}
                                                                `
                                                    });
                                        
                                                    return equiposTemplate;
                                                        }
                                                    }/>
                                                    <ExcelColumn label="Reason" value="reason"/>
                                                    <ExcelColumn label="Email" value="employee_mail"/>
                                                    <ExcelColumn label="Territory" value="territory"/>
                                                </ExcelSheet>
                                    </ExcelFile> */}
                                </div>
                            </div>
                            
                        </div>
                        <div className='card-footer'></div>
                    </div>
                </div>
                
            </div>

            <hr/>
        </div>


         <div className='container'>
            <div className="form-group text-center">
                <button 
                type="button"
                className="btn btn-primary font-weight-bold d-block w-100"
                onClick={e => handleSearch(department, territory,true, dayBegining, dayEnd)}
                >
                    Search
                </button>        
            </div>
        </div> 

        <div className='container'>
          <div className='row mt-5'>
          <table className="table table-striped table-to-card-employees">
                     <thead className="bg-primary table-dark">
                         <tr
                         >
                             <th scope="col">#</th>
                             <th scope="col">Employee</th>
                             <th scope="col">Department</th>
                             <th scope="col">Equipment Requested</th>
                             <th scope="col">Date</th>
                             <th scope="col">Status</th>
                            
                             <th scope="col" className="col-actions">Actions</th>
                         </tr>   
                     </thead>
                     <tbody>
                         {
                         requests.length > 0
                         ?
                            requests.map(request =>(
                                <FixedAssetRequests
                                key={request.id}
                                request = {request}
                                />
                                
                            ))
                         :
                                
                            <div style={{'position': 'absolute'}} className='mt-3 alert alert-warning'>Please choose the search criteria to obtain the list of orders.</div>
                                    
                         }
                     </tbody>
                 </table>    
            </div>    
        </div>                                      
        </>
    );
};

export default FixedAssetRequestPanel;