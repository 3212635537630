import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import EmployeesSupervisorManager from './EmployeesSupervisorManager';

//Redux
import { useDispatch, useSelector } from 'react-redux';

import {obtenerDepartamentos, obtenerCompanies, obtenerTerritories, obtenerEmpleadosManager} from '../actions/manageActions';
//import {obtenerUsuarioBalanceHoras} from '../actions/authenticateActions';
import Spinner from './helpers/Spinner';

import ReactExport from "react-export-excel";
import moment from 'moment';
// import {
//     BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
//   } from 'recharts';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ManageDepartmentSupervisor = () => {
     // Mandar llamar la accion principal para retornar los PTO

     const dispatch = useDispatch();

     const departmentSupervisor = useSelector(state=>state.userInfo.userInfo.department);
     const [saveDepartment, selectSaveDepartment] = useState(departmentSupervisor);
     const [saveCompany, selectSaveCompany] = useState('');
     const [saveTerritory, selectSaveTerritory] = useState('');
     const [employeeSearch, setemployeeSearch] = useState('');
     const [employeefilters, setemployeeFilters] = useState([]);
    // const [sickDays, saveSickDays] = useState([]);

     //acceder al state
     const userInfo = useSelector(state=>state.manager.employees);
     
    // const departments = useSelector(state=>state.manager.departments);
     const territories = useSelector(state => state.manager.territories);
     const companies = useSelector(state=>state.manager.companies);
     //const employeeManager = useSelector(state => state.manager.employees)

     //const employees = userInfo.userHour;
     let employees = userInfo;
     let departments = [];

     const filterElementsByName  = (array, query) => {
        return array.filter(function(el) {
            let name = `${el.employee_name} ${el.employee_lastname}`;
           // console.log(name)
            return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    const filterElementsByCompany  = (array, query) => {
    return array.filter(function(el) {
        // console.log(name)
        return el.company.toLowerCase().indexOf(query.toLowerCase()) > -1;
    })
    }

    const filterElementsByTerritory  = (array, query) => {
        return array.filter(function(el) {
            return el.territory.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
        }
    const filterElementsByDepartment  = (array, query) => {
        return array.filter(function(el) {
            return el.department.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
        }
    


    const handleSearchEmployee =  async () => {
       //console.log(employeeSearch.length)
        if(employeeSearch.length === 0){
            await setemployeeFilters(userInfo);
        }

        let filterbyName = filterElementsByName(userInfo, employeeSearch);

        let filterbyCompany = filterElementsByCompany(filterbyName, saveCompany);

        let filterbyTerritory = filterElementsByTerritory(filterbyCompany, saveTerritory);

        let filterbyDepartment = filterElementsByDepartment(filterbyTerritory, saveDepartment);

        await setemployeeFilters(filterbyDepartment);

         //console.log(employeefilters)
    }
    
     departments = useSelector(state=>state.manager.departments);
    // console.log(departments)
    let departmentSupervisorFilter = filterElementsByDepartment(departments, departmentSupervisor);
    let director_of = JSON.parse(departmentSupervisorFilter[0].director_of);


    //  if(departmentSupervisor === 'CEO' || departmentSupervisor === 'CFO' || departmentSupervisor === 'Sales' || departmentSupervisor === 'Operations' || departmentSupervisor === 'Controller' || departmentSupervisor === 'Purchasing') {

     
    //     if(departmentSupervisor === 'CFO') {
    //     departments = [
    //         {department: 'Accounting'},
    //         {department: 'Accounting CSI'},
    //         {department: 'Controller'},
    //         {department: 'Finance'},
    //         {department: 'Human Resources'},
    //         {department: 'I.T.'},
    //         {department: 'Processing'}
    //     ];
    //     }

    //     if(departmentSupervisor === 'Sales') {
    //         departments = [
    //             {department: 'Regional West'},
    //             {department: 'Regional Southeast'},
    //             {department: 'Regional Southcentral'},
    //             {department: 'Regional Midwest'},
    //             {department: 'Regional Northeast'},
    //             {department: 'Business Intelligence'}
    //         ]
    //     }

    //     if(departmentSupervisor === 'POS Execution Manger') {
    //         departments = [
    //             {department: 'Regional West'},
    //             {department: 'Regional Southeast'},
    //             {department: 'Regional Southcentral'},
    //             {department: 'Regional Midwest'},
    //             {department: 'Regional Northeast'}
    //         ]
    //     }

    //     if(departmentSupervisor === 'Operations') {
    //         departments = [
    //             {department: 'Logistics'},
    //             {department: 'Logistics Key Accounts'},
    //             {department: 'Purchasing'},
    //             {department: 'Inventory'}
    //         ]
    //     }

    //     if(departmentSupervisor === 'Controller') {
    //         departments = [
    //             {department: 'Accounting'},
    //             {department: 'Finance'},
    //             {department: 'Accounting CSI'}
    //         ]
    //     }
    //     if(departmentSupervisor === 'Purchasing') {
    //         departments = [
    //             {department: 'Inventory'},
    //             {department: 'Purchasing'}
    //         ]
    //     }
    //     if(departmentSupervisor === 'CEO') {
    //       const loadDepartments = ()=> dispatch(obtenerDepartamentos());
    //       loadDepartments();   
    //     }
    //  }else {
    //      departments = [
    //          {department: departmentSupervisor}
    //      ]
    //  }
    // let departments = [];

 
     useEffect(()=> {
        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();
        

        const loadCompanies = ()=> dispatch(obtenerCompanies());
        loadCompanies();

        const loadTerritories = ()=> dispatch(obtenerTerritories());
        loadTerritories();


        // if(saveDepartment !== '' && saveCompany !== '' && saveTerritory !== '') {
        // //   const loadEmployees = () => dispatch(obtenerUsuarioBalanceHoras(saveDepartment));
        // //   loadEmployees(); 
        //     let data = {
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType: 2
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        // }
        // if(saveDepartment === '' && saveCompany === '' && saveTerritory === '') {
        //     let data = {
        //         reportType:1
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        // }

        // if(saveDepartment !== '' && saveCompany === '' && saveTerritory === '') {
        //     let data = {
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:3
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment === '' && saveCompany !== '' && saveTerritory === '') {
        //     let data = {
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:4
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment === '' && saveCompany === '' && saveTerritory !== '') {
        //     let data = {
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:5
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment !== '' && saveCompany !== '' && saveTerritory === '') {
        //     let data = {
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:6
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment === '' && saveCompany !== '' && saveTerritory !== '') {
        //     let data = {
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:7
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }

        // if(saveDepartment !== '' && saveCompany === '' && saveTerritory !== '') {
        //     let data = {
        //         company: saveCompany,
        //         territory: saveTerritory,
        //         department: saveDepartment,
        //         reportType:8
        //     }
        //     const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        //     loadEmployeesManager();
        //     console.log(data);
        // }
        
        handleSearchEmployee();
         
     },[dispatch]);
 
     return (
         
         employees !== undefined && employees !== ''
         ? 
             <React.Fragment>
                 
                 <div className="d-flex justify-content-center align-items-center manager-title">
                    <h2 className="text-center mt-3 title-custom">SUPERVISOR MANAGER</h2>
                    <h4 className="text-center my-3" style={{width:'100%'}}>{departmentSupervisor}</h4>
                    <Link
                        to={'/pto/year-calendar'}
                        className="btn btn-success ml-3 calendar-view"
                    >
                        Calendar View
                    </Link>

                <ExcelFile element={<button className="btn btn-info  ml-3">General Info Report</button>}>
                    <ExcelSheet  data={employees} name="Employees">
                                <ExcelColumn label="Lastname" value="employee_lastname"/>
                                <ExcelColumn label="Firstname" value="employee_name"/>
                                <ExcelColumn label="Department" value="department"/>
                                <ExcelColumn label="Company" value="company"/>
                                <ExcelColumn label="Email" value="email"/>
                                <ExcelColumn label="Territory" value="territory"/>
                                <ExcelColumn label="Supervisor Email" value="supervisor_mail"/>
                                <ExcelColumn label="Hire Date" value={(col) => moment(col.hire_date).format('YYYY-MM-DD')}/>
                                <ExcelColumn label="Available Hours" value="available_hours"/>
                                <ExcelColumn label="Carry Over" value="carry_over"/>
                                <ExcelColumn label="Sick Days" value="sick_days_limit"/>
                             </ExcelSheet>
                </ExcelFile>
                
                 </div>
 
                 {/* <div className="card mb-3 avatar" > */}
                    <div className="row no-gutters m-3">
                        {/* <div className="card-body"> */}
                            
                                {/* <div className="col-sm-12">
                                    <div className="form-group">
                                            <label>Employee:</label>
                                            <select className="custom-select"
                                            onChange = {e => selectSaveDepartment(e.target.value)} 
                                            required
                                            >
                                                <option value="All">Select All...</option>
                                                {employees.map((employee, index) => (
                                                    <option key = {index} value = {employee.uid}>{employee.employee_name}</option>
                                                ))}
                                                
                                            </select>
                                    </div>
                                </div> */}
                        {/* </div> */}
                            <div className="col-md-4">
                                        <div className="form-group text-center">
                                                <label>Company:</label>
                                                <select className="custom-select"
                                                onChange = {e => selectSaveCompany(e.target.value)}
                                                required
                                                >
                                                    <option value="">Select Company...</option>
                                                    {companies.map((company, index) =>(
                                                    <option key={index} value={company.company}>{company.company}</option>
                                                    ))}
                                                </select>
                                        </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group text-center">
                                            <label>Department:</label>
                                            <select className="custom-select"
                                            onChange = {e => selectSaveDepartment(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Department...</option>
                                                {director_of.map((department, index) =>(
                                                   <option key={index} value={department.department}>{department.department}</option>
                                                ))}
                                            </select>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group text-center">
                                            <label>Territory:</label>
                                            <select className="custom-select"
                                            onChange = {e => selectSaveTerritory(e.target.value)}
                                            required
                                            >
                                                <option value="">Select Territory...</option>
                                                {territories.map((territory, index) =>(
                                                   <option key={index} value={territory.territory}>{territory.territory}</option>
                                                ))}
                                            </select>
                                    </div>
                                </div>
                    </div>
                    <div className='row no-gutters m-2'>
                        <div className="col-md-8 p-3">
                                    <div className="form-group text-center">
                                        {/* <label>Search Employee:</label>       */}
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="Search Employee..."
                                            value={employeeSearch}
                                            onChange={e => setemployeeSearch(e.target.value)}
                                            required
                                        />   
                                         
                                    </div>
                        </div>

                        <div className="col-md-4 p-3">
                                    <div className="form-group text-center">
                                        <button 
                                        type="button"
                                        className="btn btn-primary font-weight-bold d-block w-100"
                                        onClick={e => handleSearchEmployee()}
                                        >
                                            Search
                                        </button>        
                                    </div>
                        </div>
                </div>
                 {/* </div> */}
                 <div className="charts m-5 justify-content-center align-items-center">
                    {/* <BarChart
                        width={800}
                        height={300}
                        data={employees}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="employee_name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="available_hours" fill="#f0ad4e" />
                        <Bar dataKey="requested_hours" fill="#004185" />
                    </BarChart> */}
                 </div>
                 <table className="table table-striped table-to-card-employees mb-5 mt-3">
                     <thead className="bg-primary table-dark">
                         <tr>
                             <th scope="col">Employee</th>
                             <th scope="col">Department</th>
                             <th scope="col">Hire Date</th>
                             <th scope="col">Last Hour Increment</th>
                             <th scope="col">Available Hours</th>
                             <th scope="col">Carry Over</th>
                             <th scope="col">Sick Days Balance</th>
                             <th scope="col" className="col-actions">Actions</th>
                         </tr>   
                     </thead>
                     <tbody>
                        {
                            employeefilters.length > 0
                            ?
                                employeefilters.map(employee =>(
                                    <EmployeesSupervisorManager
                                    key={employee.id}
                                    employee = {employee}
                                    />
                                    
                                ))
                            :
                                    
                                <div style={{'position': 'absolute'}} className='mt-3 alert alert-danger'>The employee you are looking for is not in the selected department, please change the territory, company or department.</div>
                                            
                        }
                     </tbody>
                 </table>
                 
                 
     
             </React.Fragment>
             :
             <Spinner />
     ); 
};

export default ManageDepartmentSupervisor;