import React, {useState, useEffect, useRef, Fragment} from 'react';
//import ReactDom from 'react-dom';

// Redux
import { validarFormularioAction, validacionExito, validacionError } from '../actions/validacionActions';
import {createNewPersonalRequestAction} from '../actions/personalrequestActions';
import { useDispatch, useSelector } from 'react-redux';
import {obtenerDepartamentos} from '../actions/manageActions';

// library
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment-business-days';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import axiosHr from '../config/axiosHR';
import clienteAxiosHR from '../config/axiosHR';
 
import "react-datepicker/dist/react-datepicker.css";

const NewPersonalRequest = ({history}) => {

     var holidaysArray = ['04-02-2021', '05-31-2021', '07-05-2021', '09-06-2021', '11-25-2021', '11-26-2021', '12-23-2021', '12-24-2020', '01-01-2021', '01-01-2022', '04-15-2022', '05-30-2022', '07-04-2022', '09-05-2022', '11-24-2022', '11-25-2022', '12-23-2022', '12-26-2022', '01-02-2023', '04-07-2023', '07-04-2023', '05-29-2023', '09-04-2023', '11-23-2023', '11-24-2023', '12-22-2023', '12-25-2023', '01-01-2024', '03-29-2024', '05-27-2024', '04-07-2024', '09-02-2024', '11-28-2024', '11-29-2024', '12-24-2024', '12-25-2024'];

    var excludedDatesArray = [new Date("2021-02-04T12:00:00-00:00"), new Date("2021-05-31T12:00:00-00:00"), new Date("2021-07-05T12:00:00-00:00"), new Date("2021-09-06T12:00:00-00:00"), new Date("2021-11-25T12:00:00-00:00"), new Date("2021-11-26T12:00:00-00:00"), new Date("2021-12-23T12:00:00-00:00"), new Date("2021-12-24T12:00:00-00:00"),new Date("2022-01-01T12:00:00-00:00"), new Date("2021-01-01T12:00:00-00:00"), new Date("2022-04-15T12:00:00-00:00"), new Date("2022-05-30T12:00:00-00:00"), new Date("2022-07-04T12:00:00-00:00"), new Date("2022-09-05T12:00:00-00:00"), new Date("2022-11-24T12:00:00-00:00"), new Date("2022-11-25T12:00:00-00:00"), new Date("2022-12-23T12:00:00-00:00"), new Date("2022-12-26T12:00:00-00:00"), new Date("2023-01-02T12:00:00-00:00"), new Date("2023-04-07T12:00:00-00:00"), new Date("2023-05-29T12:00:00-00:00"), new Date("2023-07-04T12:00:00-00:00"), new Date("2023-09-04T12:00:00-00:00"), new Date("2023-11-23T12:00:00-00:00"), new Date("2023-11-24T12:00:00-00:00"), new Date("2023-12-22T12:00:00-00:00"), new Date("2023-12-25T12:00:00-00:00"), new Date("2024-01-01T12:00:00-00:00"), new Date("2024-03-29T12:00:00-00:00"), new Date("2024-05-27T12:00:00-00:00"), new Date("2024-07-04T12:00:00-00:00"), new Date("2024-09-02T12:00:00-00:00"), new Date("2024-11-28T12:00:00-00:00"), new Date("2024-11-29T12:00:00-00:00"), new Date("2024-12-24T12:00:00-00:00"), new Date("2024-12-25T12:00:00-00:00"), new Date("2025-01-01T12:00:00-00:00")]
   

    // set working days
    moment.updateLocale('us', {
        workingWeekdays: [1, 2, 3, 4, 5],
        holidays: holidaysArray,
        holidayFormat: 'MM-DD-YYYY'

     });


    // get data from state
    const error = useSelector((state) => state.error.error);
    //acceder al state
    const userInfo = useSelector(state=>state.userInfo);

    // // acceder al state de la lista de empleados
    // const employees = useSelector(state=>state.manager.employees);

    // obtener departamentos
    const departments = useSelector(state=>state.manager.departments);

    const filterDepartment  = (array, query) => {
        return array.filter(function(el) {
            let departmentElement = el.department
            return departmentElement.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    // state
    const [dateSubmitted, saveDateSubmitted] = useState('');
    const [positionTitle, savePositionTitle] = useState('');
    const [department, saveDepartment] = useState('');
    const [costCenter, saveCostCenter] = useState('');
    const [status, saveStatus] = useState('H.R. Approval');
   
	const [requestorName, saveRequestorName] = useState(`${userInfo.userInfo.name} ${userInfo.userInfo.lastname}`);
	const [requestorTitle, saveRequestorTitle] = useState(userInfo.userInfo.job_title);
    const [signatureEmployee, saveSignatureEmployee] = useState('');
    const [dateSignEmployee, saveDateSignEmployee] = useState(new Date());

	const [requestedStartDate, saveRequestedStartDate] = useState('');
	const [hiringManager, saveHiringManager] = useState('');
	const [contactNumber, saveContactNumber] = useState('');
	
	//const [employmentClassification, saveEmploymentClassification] = useState('');
	const [exempt, saveExempt] = useState(false);
	const [nonExempt, saveNonExempt] = useState(false);
	const [fullTime, saveFullTime] = useState(false);
	const [partTime, savePartTime] = useState(false);
	const [other, saveOther] = useState(false);
	const [otherText, saveOtherText] = useState('');

	const [shiftFrom, saveShiftFrom] = useState('');
	const [shiftTo, saveShiftTo] = useState('');
	const [monday, saveMonday] = useState(false);
	const [tuesday, saveTuesday] = useState(false);
	const [wednesday, saveWednesday] = useState(false);
	const [thursday, saveThursday] = useState(false);
	const [friday, saveFriday] = useState(false);
	const [saturday, saveSaturday] = useState(false);
	const [sunday, saveSunday] = useState(false);

	const [additionalCommentWorkSchedule, saveadditionalCommentWorkSchedule] = useState('');
	const [newPosition, saveNewPosition] = useState(false);
	const [positionExist, savePositionExist] = useState("yes");
	const [budgeted, saveBudgeted] = useState("yes");
	const [existingPositionReplacement, saveExistingPositionReplacement] = useState('');
	const [dutiesChanged, saveDutiesChanged] = useState("yes");

	const [positionInformation, savePositionInformation] = useState('');
	const [dailyActivities, saveDailyActivities] = useState('');
	const [measureProductivity, saveMeasureProductivity] = useState('');
	const [financialImpact, saveFinancialImpact] = useState('');
	const [positionFit, savePositionFit] = useState('');

	const [objectives, saveObjectives] = useState('');
	const [contingencies, saveContingencies] = useState('');

	const [dateFilled, saveDateFilled] = useState('');
	const [positionFilledBy, savePositionFilledBy] = useState('');
	const [startDate, saveStartDate] = useState('');
	const [salaryOffered, saveSalaryOffered] = useState('');
	const [recruitmentSource, saveRecruitmentSource] = useState('');


  

    const isWeekday = date => {
        const day = moment(date).day();
        return day !== 0 && day !== 6;
      };
   
    const timeBeginningRef = useRef('');
    const timeEndRef = useRef('');

    // canvas
    
    // const sendEmail = (pto, supervisorEmail, email, company) => {

    //     const ptoInfo = {
    //         pto,
    //         supervisorEmail,
    //         email,
    //         company
    //       }

    //     clienteAxiosHR.post('/pto-email', ptoInfo)
    //             .then(respuesta => {
    //                 //console.log(respuesta);
    //                 Swal.fire(
    //                     'Sent',
    //                     'The Time off Request has been sent successfully.',
    //                     'success'
    //                   )

    //                 //   setTimeout(() => {
    //                 //     window.location.reload(false);
    //                 //   }, 3000);
    //             })
    //             .catch(error => {
    //                 console.log(error);
    //                 Swal.fire(
    //                     'Failed',
    //                     'An error was occurred by sending your request.',
    //                     'warning'
    //                   )
    //             })
    // }    


    let sigPadEmployee = {};
    const clearEmployee = (e) => {
        e.preventDefault();
        sigPadEmployee.clear();
      }
    const saveCanvasEmployee = (e)=> {
        e.preventDefault();
        let urlImage = sigPadEmployee.getTrimmedCanvas().toDataURL('image/png');
        saveSignatureEmployee(urlImage);
    }


    // const handleChangeInput = (event) => {
    //     const {value} = event.target;
    //     let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

    //     if(regex.test(value)) {
    //        // console.log(value);
    //       //  saveReason(value);
    //     } else {
    //         console.log("Es un caracter no válido")
    //     }
    // }


    const handleCheckbox = (value) => {
        //console.log(value);
        if(value === 'exempt') {
            saveExempt(!exempt);
        } else if(value === 'non-exempt') {
            saveNonExempt(!nonExempt);
        } else if(value === 'full-time') {
            saveFullTime(!fullTime);
        } else if(value === 'part-time') {
            savePartTime(!partTime);
        } else if(value === 'other') {
            saveOther(!other);
        } else if(value === 'monday') {
            saveMonday(!monday);
        } else if(value === 'tuesday') {
            saveTuesday(!tuesday);
        } else if(value === 'wednesday') {
            saveWednesday(!wednesday);
        } else if(value === 'thursday') {
            saveThursday(!thursday);
        } else if(value === 'friday') {
            saveFriday(!friday);
        } else if(value === 'saturday') {
            saveSaturday(!saturday);
        } else if(value === 'sunday') {
            saveSunday(!sunday);
        }else if (value === 'newPosition') {
            saveNewPosition(!newPosition);
            savePositionExist('no');
            saveExistingPositionReplacement('');
        }

    }


    // dispatch actions
    const dispatch = useDispatch();
     const agregarRecruitmentRequest = (request) => dispatch (createNewPersonalRequestAction(request));
    // const balanceHoras = (horas) => dispatch(ajustarBalanceHoras(horas));
     const validarFormulario = () => dispatch( validarFormularioAction());
     const exitoValidacion = () => dispatch( validacionExito() ) ;
     const errorValidacion = () => dispatch( validacionError() ) ;
    const loadDepartments = ()=> dispatch(obtenerDepartamentos());
       
    useEffect(() => {
        loadDepartments();
    }, [])

    
    // Add new Request
    const submitNewPersonalRequest = e => {
      e.preventDefault();
    //  console.log(carryOver)
      validarFormulario();
    //   if(employeeName.trim() === ''|| department.trim() === '' || signatureEmployee.trim() === '' || dateSignEmployee === 'Invalid date' ) {
    //       errorValidacion();
    //       return
    //   }

      exitoValidacion();

      // Preguntar al usuario
      Swal.fire({
        title: 'Request Summary',
        text: "This is your summary request, are you sure to submit ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        // html: '<div>'+
        //             '<p> Name : ' + employeeName + '</p>' +
        //             '<p> Department : ' + department + '</p>' +
        //             //00'<p> Reason : ' + reason + ' </p>' +
        //            //'<p> Total Hours: ' + totalHours + '</p>' +
        //             // '<p> Absence Code: ' + absenceText + '</p>' +
        //          '</div>'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            'Saved',
            'Your request has been sent successfully',
            'success'
          )
          let dateSignature = moment(dateSignEmployee).format('YYYY-MM-DD');
         

          //const company = userInfo.userInfo.company
         
          let dateSubmittedFormatted = moment(dateSubmitted).format('YYYY-MM-DD');
          let requestStartDateFormatted = moment(requestedStartDate).format('YYYY-MM-DD');
          

          let shiftFromFormatted = moment(shiftFrom).format('HH:mm')
          let shiftToFormatted = moment(shiftTo).format('HH:mm')


          if(newPosition === true) {
            saveNewPosition(true);
          }else {
            saveNewPosition(false);
          }

            // if(exempt) {
            //     saveExempt(true);
            // }else {
            //     saveExempt(false);
            // }

            // if(nonExempt) {
            //     saveNonExempt(true);
            // }else {
            //     saveNonExempt(false);
            // }

            // if(fullTime) {
            //     saveFullTime(true);
            // }else {
            //     saveFullTime(false);
            // }

            // if(partTime === "1") {
            //     savePartTime(true);
            // }else {
            //     savePartTime(false);
            // }

            // if(other === "1") {
            //     saveOther(true);
            // }else {
            //     saveOther(false);
            // }

            // if(monday === true) {
            //     saveMonday(1);
            // }else {
            //     saveMonday(0);
            // }

            // if(tuesday === true) {
            //     saveTuesday(1);
            // }else {
            //     saveTuesday(0);
            // }

            // if(wednesday === true) {
            //     saveWednesday(1);
            // }else {
            //     saveWednesday(0);
            // }

            // if(thursday === true) {
            //     saveThursday(1);
            // }else {
            //     saveThursday(0);
            // }

            // if(friday === true) {
            //     saveFriday(1);
            // }else {
            //     saveFriday(0);
            // }

            // if(saturday === true) {
            //     saveSaturday(1);
            // }else {
            //     saveSaturday(0);
            // }

            // if(sunday === true) {
            //     saveSunday(1);
            // }else {
            //     saveSunday(0);
            // }

            // if(budgeted === 'yes') {
            //     saveBudgeted(1);
            // }else {
            //     saveBudgeted(0);
            // }



          let recruitmentRequest = {
            status,
            dateSubmittedFormatted,
			positionTitle,
			department,
			requestStartDateFormatted,
			hiringManager,
			contactNumber,
            costCenter,
            otherText,
            exempt,
            nonExempt,
            fullTime,
            partTime,
            other,
			shiftFromFormatted,
			shiftToFormatted,
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
			additionalCommentWorkSchedule,
			newPosition,
			positionExist,
            existingPositionReplacement,
			budgeted,
			dutiesChanged,
			positionInformation,
			dailyActivities,
			measureProductivity,
			financialImpact,
            positionFit,
			objectives,
			contingencies,
            requestorName,
            requestorTitle,
            signatureEmployee,
			dateSignature,
			// dateFilled,
			// positionFilledBy,
			// startDate,
			// salaryOffered,
			// recruitmentSource

        }


        agregarRecruitmentRequest(recruitmentRequest);


       
            //redirect
            history.push('/home');
        }
    })
      
      
    }

    return (
        <div className="row justify-content-center mt-5">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <div className='row pt-3'>
                            <div className='col-md-8'>
                                <h2 className="text-center mb-4 font-weight-bold ">Recruitment Request Form</h2>
                            </div>
                            
                            
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group">
                                    <input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="Cost Center" 

                                        defaultValue=''
                                        onChange={ e => saveCostCenter(e.target.value) }
                                        required
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <form onSubmit={submitNewPersonalRequest}>
                            {/* Position Details */}
                            <h3 className='form-title'>Position Details</h3>
                            <div className="row justify-content-between">
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Date Submitted</label>
                                        <DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={dateSubmitted}
                                        onChange={date => saveDateSubmitted(date)}
                                       // filterDate={isWeekday}
                                        placeholderText="Select a date"
                                        // minDate={new Date()}
                                        // excludeDates={excludedDatesArray}
                                        required
                                        
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Position Title</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="" 
                                            onChange={ e => savePositionTitle(e.target.value) }
                                            required
                                           
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Department</label>
                                        <select 
                                            className="form-control" 
                                            value={department} 
                                            onChange={e => saveDepartment(e.target.value)} 
                                            required
                                        >
                                            <option value="">Select Department...</option>
                                            {departments.map(dept => (
                                                <option key={dept.department} value={dept.department}>
                                                    {dept.department}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Request Start Date</label>
                                        <DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={requestedStartDate}
                                        onChange={date => saveRequestedStartDate(date)}
                                        filterDate={isWeekday}
                                        placeholderText="Select a date"
                                        // minDate={new Date()}
                                        // excludeDates={excludedDatesArray}
                                        required
                                        
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Hiring Manager</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="" 
                                            onChange={ e => saveHiringManager(e.target.value) }
                                            required
                                            value={hiringManager}
                                        />
                                    </div>
                                </div>    

                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Contact Number</label>
										<input
											type="text"
											className="form-control"
											value={contactNumber}
											required
											onChange={e => saveContactNumber(e.target.value)}
										/>
                                    </div>
                                </div>
                            
                            </div>
                        
                            <hr />

                            {/* Employee Clasification */}
                            <div className="row justify-content-between">
                                <div className="col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <h3 className='d-block form-title'>Employee Classification</h3>
                                        <div className='row'> 
                                            {/* Begin Row */}
                                        <div className='col-md-6'>
                                            <div className="custom-control custom-switch custom-control-inline">
                                                <input 
                                                    className="custom-control-input" id = "custom-switch-20"
                                                    type="checkbox" 
                                                    checked={exempt}
                                                    value={'exempt'} 
                                                
                                                    onChange={e => handleCheckbox(e.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="custom-switch-20">
                                                    Exempt
                                                </label>
                                            </div>
                                            <div className="custom-control custom-switch custom-control-inline">
                                                <input 
                                                    className="custom-control-input" id = "custom-switch-21"
                                                    type="checkbox" 
                                                    checked={nonExempt} 
                                                    value={'non-exempt'}
                                                    onChange={e => handleCheckbox(e.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="custom-switch-21">
                                                    Non-Exempt
                                                </label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="custom-control custom-switch custom-control-inline">
                                                <input 
                                                    className="custom-control-input" id = "custom-switch-22"
                                                    type="checkbox" 
                                                    checked={fullTime}
                                                    value={'full-time'}
                                                    onChange={e => handleCheckbox(e.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="custom-switch-22">
                                                    Full-Time
                                                </label>
                                            </div>
                                            <div className="custom-control custom-switch custom-control-inline">
                                                <input 
                                                    className="custom-control-input" id = "custom-switch-23"
                                                    type="checkbox" 
                                                    checked={partTime} 
                                                    value={'part-time'}
                                                    onChange={e => handleCheckbox(e.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="custom-switch-23">
                                                    Part-Time
                                                </label>
                                            </div>
                                            <div className="custom-control custom-switch custom-control-inline">
                                                <input 
                                                    className="custom-control-input" id = "custom-switch-24"
                                                    type="checkbox" 
                                                    checked={other} 
                                                    value={'other'}
                                                    onChange={e => handleCheckbox(e.target.value)}
                                                />
                                                <label className="custom-control-label" htmlFor="custom-switch-24">
                                                    Other
                                                </label>
                                                
                                            </div>

											{ other ? 
													(
                                                    <div className="form-group mt-2 w-100">
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Please specify" 
                                                            onChange={e => saveOtherText(e.target.value)} 
                                                            required 
                                                        />
                                                    </div>
                                                )
												:null
											}
                                        </div>
                                        {/* End Row */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                          
                            <hr />

                            {/* Work Schedule */}
                            <h3 className='form-title'>Work Schedule</h3>
                            <div className="row justify-content-between">
                                
                                <div className="col-md-8 col-sm-12">
                                    <div className="form-group">
                                        <label>Shift From:</label>
                                        {/* <input 
                                            type="time" 
                                            className="form-control" 
                                            onChange={e => saveShiftFrom(e.target.value)} 
                                            required 
                                        /> */}
                                        <DatePicker
                                        selected={shiftFrom}
                                        onChange={date => saveShiftFrom(date)} 
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        
                                        ref = {timeBeginningRef}
                                        required
                                        />
                                        <label>Shift To:</label>
                                        {/* <input 
                                            type="time" 
                                            className="form-control" 
                                            onChange={e => saveShiftTo(e.target.value)} 
                                            required 
                                        /> */}
                                        <DatePicker
                                        selected={shiftTo}
                                        onChange={date => saveShiftTo(date)} 
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={60}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        
                                        ref = {timeEndRef}
                                        required
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                       
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input 
                                                className="custom-control-input" id = "custom-switch-31"
                                                type="checkbox" 
                                                value={'monday'}
                                                checked={monday}
                                                onChange={e => handleCheckbox(e.target.value)}
                                            />
                                            <label className="custom-control-label" htmlFor="custom-switch-31">
                                                Monday
                                            </label>
                                        </div>
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input 
                                                className="custom-control-input" id = "custom-switch-32"
                                                type="checkbox" 
                                                value={'tuesday'}
                                                checked={tuesday}
                                                onChange={e => handleCheckbox(e.target.value)}
                                            />
                                            <label className="custom-control-label" htmlFor="custom-switch-32">
                                                Tuesday
                                            </label>
                                        </div>
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input 
                                                className="custom-control-input" id = "custom-switch-33"
                                                type="checkbox" 
                                                value={'wednesday'}
                                                checked={wednesday}
                                                onChange={e => handleCheckbox(e.target.value)}
                                            />
                                            <label className="custom-control-label" htmlFor="custom-switch-33">
                                                Wednesday
                                            </label>
                                        </div>
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input 
                                                className="custom-control-input" id = "custom-switch-34"
                                                type="checkbox" 
                                                value={'thursday'}
                                                checked={thursday}
                                                onChange={e => handleCheckbox(e.target.value)}
                                            />
                                            <label className="custom-control-label" htmlFor="custom-switch-34">
                                                Thursday
                                            </label>
                                        </div>
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input 
                                                className="custom-control-input" id = "custom-switch-35"
                                                type="checkbox" 
                                                value={'friday'}
                                                checked={friday}
                                                onChange={e => handleCheckbox(e.target.value)}
                                            />
                                            <label className="custom-control-label" htmlFor="custom-switch-35">
                                                Friday
                                            </label>
                                        </div>
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input 
                                                className="custom-control-input" id = "custom-switch-36"
                                                type="checkbox" 
                                                value={'saturday'}
												checked={saturday}
                                                onChange={e => handleCheckbox(e.target.value)}
                                            />
                                            <label className="custom-control-label" htmlFor="custom-switch-36">
                                                Saturday
                                            </label>
                                        </div>
                                        <div className="custom-control custom-switch custom-control-inline">
                                            <input
                                                className="custom-control-input" id = "custom-switch-37"
                                                type="checkbox" 
                                                value={'sunday'}
												checked={sunday}
                                                onChange={e => handleCheckbox(e.target.value)}
                                            />
                                            <label className="custom-control-label" htmlFor="custom-switch-37">
                                                Sunday
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <div className="form-group">
										<label>Additional Comments on Work Schedule:</label>
										<textarea 
												className="form-control"
												value={additionalCommentWorkSchedule}
												onChange={e=>saveadditionalCommentWorkSchedule(e.target.value)}
												//onChange = {handleChangeInput}
												required
												>
										</textarea>
								</div>
                                </div>
                            </div>

							<hr />
							{/* Position Information */}
							<h3 className='form-title'>Position Information</h3>
							<div className="row justify-content-between">
									<div className="col-md-4 col-sm-12">
										<div className="form-group">
											<div className="custom-control custom-switch custom-control-inline">
												<input 
													className="custom-control-input" id="custom-switch-25"
													type="checkbox" 
													checked={newPosition} 
                                                    value="newPosition"
													onChange={e => handleCheckbox(e.target.value)}
												/>
												<label className="custom-control-label" htmlFor="custom-switch-25">
													New Position
												</label>
											</div>

											
										</div>
									</div>

									<div className="col-md-4 col-sm-12">
										<div className="form-group">
											<label>Does this position exist now in your team?</label>
											<select 
												className="form-control" 
												value={positionExist} 
												onChange={e => savePositionExist(e.target.value)} 
												required
                                                disabled = {newPosition === true ? true : false} 
											>
												
												<option value="yes">Yes</option>
												<option value="no">No</option>
                                                
											</select>
										</div>

										<div className="form-group">
											<label>Budgeted</label>
											<select 
												className="form-control" 
												value={budgeted} 
												onChange={e => saveBudgeted(e.target.value)} 
												required
											>
												
												<option value="yes">Yes</option>
												<option value="no">No</option>
											</select>
										</div>
									</div>

									<div className="col-md-4 col-sm-12">
										<div className="form-group">
											<label>Existing Position Replacement for (name)</label>
											<input 
												type="text" 
												className="form-control" 
												placeholder="Enter name"
                                                value={existingPositionReplacement} 
												onChange={e => saveExistingPositionReplacement(e.target.value)} 
												required
                                                disabled = {newPosition === true ? true : false} 
											/>
										</div>
									</div>

									<div className="col-md-12">
										<div className="form-group">
											<label>Have the duties of this position changed?</label>
											<select 
												className="form-control" 
												value={dutiesChanged} 
												onChange={e => saveDutiesChanged(e.target.value)} 
												required
											>
												
												<option value="yes">Yes</option>
												<option value="no">No</option>
											</select>
											
										</div>

										<div className="form-group">
											<label>Position Information: (complete only if no job description exists or the position has changed)</label>
											<textarea
												className="form-control"
												value={positionInformation}
												onChange={e=>savePositionInformation(e.target.value)}
												//onChange = {handleChangeInput}
												required
												>
											</textarea>
										</div>

										<div className="form-group">
											<label>What daily activities/objectives does the role address?</label>
											<textarea
												className="form-control"
												value={dailyActivities}
												onChange={e=>saveDailyActivities(e.target.value)}
												//onChange = {handleChangeInput}
												required
												>
											</textarea>
										</div>

										<div className="form-group">
											<label>How do you measure productivity? (volume of activity)</label>
											<textarea
												className="form-control"
												value={measureProductivity}
												onChange={e=>saveMeasureProductivity(e.target.value)}
												//onChange = {handleChangeInput}
												required
												>
											</textarea>
										</div>

										<div className="form-group">
											<label>Explain the financial impact of this position (cost vs. ROI, cost savings, revenue expected)</label>
											<textarea
												className="form-control"
												value={financialImpact}
												onChange={e=>saveFinancialImpact(e.target.value)}
												//onChange = {handleChangeInput}
												required
												>
											</textarea>
										</div>

										<div className="form-group">
											<label>Explain how this position will fit into the current team structure?</label>
											<textarea
												className="form-control"
												value={positionFit}
												onChange={e=>savePositionFit(e.target.value)}
												//</div></div>onChange = {handleChangeInput}
												required
												>
											</textarea>
										</div>

										<div className="form-group">
											<label>What objectives, risks, or business challenges are driving this request?</label>
											<textarea
												className="form-control"
												value={objectives}
												onChange={e=>saveObjectives(e.target.value)}
												//onChange = {handleChangeInput}
												required
												>
											</textarea>
										</div>

										<div className="form-group">
											<label>What contingencies exist if the position is not approved?</label>
											<textarea
												className="form-control"
												value={contingencies}
												onChange={e=>saveContingencies(e.target.value)}
												//onChange = {handleChangeInput}
												required
												>
											</textarea>
										</div>

										
									</div>
							</div>

							<hr />
							{/*  Approvals && Notifications */}

							<h3 className='d-block form-title'>Approvals & Notifications</h3>
                            <div className="row container justify-content-between">
                                
							<div className="col-md-8 col-sm-12">
                                 
                                    <div className="form-group">
                                    <label>Requestor Name:</label>
                                        <input
										type="text"
										className="form-control"
										value={requestorName}
										required
										onChange={e => saveRequestorName(e.target.value)}
										/>
                                        
                                    </div>
                                   
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Requestor Title:</label>
										<input 
											type="text" 
											className="form-control" 
											value={requestorTitle}
											required
											onChange={e => saveRequestorTitle(e.target.value)}
										/>
                                    </div>
                                </div>

                                
                                <div className="col-md-8 col-sm-12">
                                
                                    <div clasname="form-group">
                                    <label style={{fontSize:'1.4rem'}}>Requestor Signature:</label>
                                        <div className="signatureContainer" style={{width:'100%'}}>
                                        
                                            <SignatureCanvas penColor = 'black' canvasProps={{
                                                //width:'90%',
                                                //height: '100%',
                                                className: 'signatureCanvas'
                                            }} ref={ref => {sigPadEmployee = ref}} 
                                            onEnd = {saveCanvasEmployee}
                                            />
                                            {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                            
                                        </div>
                                    </div>
                                    <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button>
                                </div>

                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group">
                                        <label>Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDateSignEmployee(e.target.value)}
                                        /> */}
                                        <DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={dateSignEmployee}
                                        onChange={date => saveDateSignEmployee(date)}
                                        filterDate={isWeekday}
                                        placeholderText="Select a weekday"
                                        minDate={new Date()}
                                        excludeDates={excludedDatesArray}
                                        required
                                        disabled
                                        />
                                    </div>
                                </div>
                                
                            </div>

                        {userInfo.userInfo.email === 'nubia.osuna@mexilink.com' || userInfo.userInfo.email === 'anthony.marban@mexilink.com'

                        ?   
                            <Fragment>
							<hr />
							{/*  Approvals && Notifications */}

							<h3 className='d-block form-title'>Human Resource Department Use Only</h3>
							<div className="row container justify-content-between">
								
								<div className="col-md-6 col-sm-12">
									<div className="form-group">
										<label>Date filled:</label>
										
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDateSignEmployee(e.target.value)}
                                        /> */}
                                        <DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={dateFilled}
                                        onChange={date => saveDateFilled(date)}
                                        
                                        placeholderText="Select a date"
                                        
                                        required
                                        
                                        />
									</div>
								</div>

								<div className="col-md-6 col-sm-12">
									<div className="form-group">
										<label>Position Filled by:</label>
										<input 
											type="text" 
											className="form-control" 
											value={positionFilledBy}
											required
											onChange={e => savePositionFilledBy(e.target.value)}
										/>
									</div>
								</div>

								<div className="col-md-6 col-sm-12">
									<div className="form-group">
										<label>Start Date:</label>
										<DatePicker
                                        className="datePicker"
                                        todayButton="Today"
                                        selected={startDate}
                                        onChange={date => saveStartDate(date)}
                                       
                                        placeholderText="Select a date"
                                       
                                        required
                                        
                                        />
									</div>
								</div>

								<div className="col-md-6 col-sm-12">
									<div className="form-group">
										<label>Salary Offered:</label>
										<input 
											type="text" 
											className="form-control" 
											value={salaryOffered}
											required
											onChange={e => saveSalaryOffered(e.target.value)}
										/>
									</div>
								</div>

								<div className="col-md-12 col-sm-12">
									<div className="form-group">
										<label>Recruitment Source:</label>
										<input 
											type="text" 
											className="form-control" 
											value={recruitmentSource}
											required
											onChange={e => saveRecruitmentSource(e.target.value)}
										/>
									</div>
								</div>

							</div>
                            </Fragment>
                        : null
                            }


                            <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4 mt-4">Submit</button>
                        
                        </form>

                        { error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please fill out all the required fields. </div> : null }
        
                        </div>
                    </div>
                </div>
            
        </div>
    );

    
};

export default NewPersonalRequest;