import React, { useEffect } from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';


import {useSelector } from 'react-redux';


const EmployeesTranslateHours = ({employee, history, incrementRules}) => {

  //  acceder al state
   const userInfo = useSelector(state=>state.userInfo);

    if(userInfo.userInfo.name === undefined){
       // history.push('/home');
    }

     
    

    useEffect(() => {

      incrementRules(employee);
      
    }, [])
    

    
    

    return (
        <tr style={employee.terminate_contract === 1 ? {backgroundColor: '#fdd5d5'} : null}>
            <td>{employee.employee_name}{' '}{employee.employee_lastname}</td>
            <td>{employee.department}</td>
            <td>{moment.utc(employee.hire_date).format('YYYY-MM-DD')}</td>
            {/* <td>{moment.utc(employee.increase_date).format('YYYY-MM-DD')}</td> */}
            <td>{employee.available_hours}</td>
            <td>{employee.carry_over}</td>
            <td>{employee.sick_days_limit}</td>
            <td>{employee.carry_over_sick_time}</td>
            <td>
                <div className='justify-content-center w-50'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3" />
                </svg>
                </div>
            </td>
            <td>{incrementRules(employee).newAvailableHours}</td>
            <td>{incrementRules(employee).seniority}</td>
            <td>{incrementRules(employee).newCarryOver}</td>
            <td>{incrementRules(employee).newSickDays}</td>
            <td>{incrementRules(employee).newCarryOverSickDays}</td>
            <Link
                to={`/users/edit/${employee.uid}`}
                className="btn btn-warning m-1 btn-sm"
            >
                Edit Profile
            </Link>
        </tr>
    );
};

export default EmployeesTranslateHours;