import React, {useState, useEffect, useRef} from 'react';
//import ReactDom from 'react-dom';

// Redux
import { useDispatch } from 'react-redux';
import {editarUsuario} from '../actions/authenticateActions';
import {obtenerEmpleadosManager, obtenerDepartamentos, obtenerEstadosActivos} from '../actions/manageActions';
// library
import moment from 'moment-business-days';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import { useSelector } from 'react-redux';
 
import "react-datepicker/dist/react-datepicker.css";
import axiosHr from '../config/axiosHR';

const EditUser = ({match, history}) => {

    //acceder al state
    const userInfo = useSelector(state=>state.userInfo);

    // obtener el ID a editar
    const { uid } = match.params;

    const usersHour = useSelector(state=>state.manager.employees);

    // obtener departamentos
    const departments = useSelector(state=>state.manager.departments);

    const statesTerritory = useSelector(state=>state.manager.states);
    // get user general data
    const employeeData = usersHour.filter(user => user.uid == uid);

    const companyManager = useSelector(state=>state.userInfo.userInfo.company);

    if(employeeData === undefined) {
        history.push('/home');
    }

    const filterDepartmentbyName  = (array, query) => {
        return array.filter(function(el) {
            let departmentElement = el.department
            return departmentElement.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    const filterDepartment  = (array, query) => {
        return array.filter(function(el) {
            let departmentElement = el.id
            // console.log(el, query)
            return departmentElement === Number(query);
        })
      }
    
    //console.log(employeeData);

  //  var holidaysArray = ['09-02-2019', '11-28-2019', '11-29-2019', '12-24-2019', '12-25-2019', '01-01-2020', '04-10-2020', '05-27-2020', '07-04-2020', '09-02-2020', '11-28-2020', '11-29-2020', '12-24-2020', '12-25-2020', '01-01-2021']

   // var excludedDatesArray = [new Date("2019-09-02T12:00:00-00:00"), new Date("2019-11-28T12:00:00-00:00"), new Date("2019-11-29T12:00:00-00:00"), new Date("2019-12-24T12:00:00-00:00"), new Date("2019-12-25T12:00:00-00:00"), new Date("2019-01-01T12:00:00-00:00"), new Date("2020-04-10T12:00:00-00:00"), new Date("2020-05-27T12:00:00-00:00"),new Date("2020-07-04T12:00:00-00:00"), new Date("2020-09-02T12:00:00-00:00"), new Date("2020-11-28T12:00:00-00:00"), new Date("2020-11-29T12:00:00-00:00"), new Date("2020-12-24T12:00:00-00:00"), new Date("2020-12-25T12:00:00-00:00"), new Date("2020-01-01T12:00:00-00:00"), new Date("2021-01-01T12:00:00-00:00"), ]
   

    // set working days
    moment.updateLocale('us', {
       // workingWeekdays: [1, 2, 3, 4, 5],
       // holidays: holidaysArray,
       // holidayFormat: 'MM-DD-YYYY'

     });


    // state
   // const [imageProfile, saveImageProfile] = useState('');
    const [chooseText, saveChooseText] = useState('Upload Profile Image');
    const [imgSrc, saveImgSrc] = useState('./../avatar-placeholder.png');
    const [imgPath, saveImgPath] = useState('');
    const [employeeName, saveEmployeeName] = useState('');
    const [employeeLastname, saveEmployeeLastname] = useState('');
    const [username, saveUserName] = useState('');
    const [company, saveCompany] = useState('');
    const [department, saveDepartment] = useState('');
    const [availableHours, saveAvailableHours] = useState('');
    const [carryOver, saveCarryOver] = useState('');
    const [sickDays, saveSickDays] = useState('');
    const [supervisor, saveSupervisor] = useState(false);
    const [email, saveEmail] = useState('');
    const [emailSupervisor, saveEmailSupervisor] = useState('');
    const [uidEmployee, saveUid] = useState('');
    const [token, saveToken] = useState('');
    const [hireDate, saveHireDate] = useState('');
    const [lastIncrement, saveLastIncrement] = useState('');
    const [accruedTimeSick, saveAccruedTimeSick] = useState('');
    const [carryOverSickTime, saveCarryOverSickTime] = useState(0);
    const [territory, saveTerritory] = useState('');
    const [travelBenefits, saveTravelBenefits] = useState(false);
    const [terminated, saveTerminated] = useState(false);
    const [terminateDate, saveTerminateDate] = useState(new Date());
    const [idDepartment, saveIdDepartment] = useState('');
    // const [statesTerritory, saveStatesTerritory] = useState ([]);
    const [gender, saveGender] = useState('');
    const [parenthood, saveParenthood] = useState(false);
    const [workplace, saveWorkplace] = useState('');
    const [phone, savePhone] = useState('');
    const [birthplace, saveBirthPlace] = useState('');
    const [birthdate, saveBirthDate] = useState('');
    const[jobTitle, saveJobTitle] = useState('');
    const [independetContractor, saveIndependentContractor] = useState(false);
    const [insperityEmployeeId, saveInsperityEmployeeId] = useState('');
    const [salesTeam, saveSalesTeam] = useState(false);

    const terminatedDateRef = useRef('');


    const isWeekday = date => {
        const day = moment(date).day();
        return day !== 0 && day !== 6;
      };

    const handleChangeInput = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveEmployeeName(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleChangeLastName = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveEmployeeLastname(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleChangeJobTitle = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveJobTitle(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleCheckTravelBenefits = (value) => {
        saveTravelBenefits(!travelBenefits);
    }

    const handleCheckTerminate = (value) => {
        console.log(value);
        var newTerminate = !terminated;
        terminatedDateRef.current.hidden = !newTerminate;
         saveTerminated(!terminated);
    }

    const handleCheckIndependentContractor = (value) => {
        console.log(value);
        saveIndependentContractor(!independetContractor);
    }

    const handleCheckSalesTeam = (value) => {
        console.log(value);
        saveSalesTeam(!salesTeam);
    }

    const getDepartmentId = (value) => {
        let departmentFilter;
        if(typeof(value) === 'string') {
             departmentFilter = filterDepartmentbyName(departments, value)
        }else {
             departmentFilter = filterDepartment(departments, value)
        }
        
        console.log('valor handle department', typeof(value))
        console.log('valor departments para filtrar',departments)
        console.log('valor department filtrado',departmentFilter[0].department)
        saveDepartment(departmentFilter[0].department)
        
        if(!supervisor) {
           // saveEmailSupervisor(departmentFilter[0].email_director);
            saveIdDepartment(departmentFilter[0].id)
        }else {
           // saveEmailSupervisor(departmentFilter[0].report_to);
            saveIdDepartment(departmentFilter[0].id)
        }
    }

    const handleDepartmentSelect = (value) => {
        let departmentFilter;
        if(typeof(value) === 'string') {
             departmentFilter = filterDepartmentbyName(departments, value)
        }else {
             departmentFilter = filterDepartment(departments, value)
        }
        
        console.log('valor handle department', typeof(value))
        console.log('valor departments para filtrar',departments)
        console.log('valor department filtrado',departmentFilter[0].department)
        saveDepartment(departmentFilter[0].department)
        
        if(!supervisor) {
            saveEmailSupervisor(departmentFilter[0].email_director);
            saveIdDepartment(departmentFilter[0].id)
        }else {
            saveEmailSupervisor(departmentFilter[0].report_to);
            saveIdDepartment(departmentFilter[0].id)
        }
    }

    const handleCheckSupervisor = (value) => {
        console.log(value)
        saveSupervisor(!supervisor);
        if(!supervisor) {
            console.log(departments)
            let departmentFilter = filterDepartmentbyName(departments, value)
            console.log(departmentFilter);
           saveEmailSupervisor(departmentFilter[0].report_to);
        }else {
            let departmentFilter = filterDepartmentbyName(departments, value)
            saveEmailSupervisor(departmentFilter[0].email_director);
        }
        
    }

    const handleCheckParenthood = (value) => {
        saveParenthood(!parenthood)
    }



   const fileChangedHandler = (event) => {
        const file = event.target.files[0];
       // saveImageProfile(file);
        saveChooseText(file.name);
        saveImgSrc(URL.createObjectURL(file));
      //  saveImgPath(`./images-directory/${file.name}`);
      fileUpload(file);
      }

    const fileUpload = (file) => {

       var reader = new FileReader(); // Creating reader instance from FileReader() API

        reader.addEventListener("load", function () { // Setting up base64 URL on image
           saveImgPath(reader.result);
        }, false);

        reader.readAsDataURL(file); // Converting file into data URL
      }

        if(employeeData[0].username === undefined) {
            history.push('./../../home');
        }

        const handleTerritory = (territoryInput) => {

            console.log(territoryInput)
            
            let sick_days_plan = statesTerritory.filter((ele => {
                let name = ele.name
                console.log(name === territoryInput)
                return name === territoryInput;
            } ))
            console.log(sick_days_plan);
            saveTerritory(sick_days_plan[0].name);
            saveAccruedTimeSick(sick_days_plan[0].sick_days_plan);
        }

        
    
    useEffect(() => {

        const loadDepartments = ()=> dispatch(obtenerDepartamentos());
        loadDepartments();

        const loadStates = ()=> dispatch(obtenerEstadosActivos(companyManager));
        loadStates();
        
       if(employeeData[0].avatar === '')
        {
            saveImgSrc('./../../../public/avatar-placeholder.png');
        }else {
            saveImgSrc(employeeData[0].avatar);
            saveImgPath(employeeData[0].avatar);
        }

        if(employeeData[0].terminate_contract === 1) {
            terminatedDateRef.current.hidden = false;
        }else {
            terminatedDateRef.current.hidden = true;
        }

        
        saveEmployeeName(employeeData[0].employee_name);
        saveEmployeeLastname(employeeData[0].employee_lastname);
        saveUserName(employeeData[0].username);
        saveCompany(employeeData[0].company);
        saveDepartment(employeeData[0].department);
        saveSupervisor(employeeData[0].supervisor);
       // handleCheckSupervisor(employeeData[0].supervisor);
        saveEmail(employeeData[0].email);
        saveEmailSupervisor(employeeData[0].supervisor_mail);
        saveUid(employeeData[0].uid);
        saveToken(employeeData[0].token);
        saveAvailableHours(employeeData[0].available_hours);
        saveCarryOver(employeeData[0].carry_over);
        saveSickDays(employeeData[0].sick_days_limit);
        saveHireDate(new Date((moment(employeeData[0].hire_date).utc().format('MM/DD/YYYY'))))
        saveLastIncrement(new Date((moment(employeeData[0].increase_date).utc().format('MM/DD/YYYY'))))
        //saveTerritory(employeeData[0].territory);
        //saveTerritory(handleTerritory(employeeData[0].territory));
        saveAccruedTimeSick(employeeData[0].accrued_sick_days);
        saveCarryOverSickTime(employeeData[0].carry_over_sick_time);
        saveTravelBenefits(employeeData[0].travel_benefits);
        saveIndependentContractor(employeeData[0].independent_contractor);
        saveSalesTeam(employeeData[0].sales_team);
        saveInsperityEmployeeId(employeeData[0].insperity_employee_id);
        saveTerminated(employeeData[0].terminate_contract);
        savePhone(employeeData[0].phone);
        if(employeeData[0].terminate_contract) {
            saveTerminateDate(new Date(moment(employeeData[0].date_terminated).utc().format('MM/DD/YYYY')));
        }
       // handleCheckTravelBenefits(employeeData[0].travel_benefits);
    //    handleDepartmentSelect(employeeData[0].department);
    //     if(employeeData[0].supervisor === 0) {
    //         handleDepartmentSelect(employeeData[0].department);
    //     }else {
    //         handleCheckSupervisor(employeeData[0].department);
    //     }
        getDepartmentId(employeeData[0].department);
        saveGender(employeeData[0].gender);
        saveParenthood(employeeData[0].parenthood);
        saveWorkplace(employeeData[0].work_place);
        saveBirthPlace(employeeData[0].birth_place);
        saveJobTitle(employeeData[0].job_title);
        saveBirthDate(new Date((moment(employeeData[0].birth_date).utc().format('MM/DD/YYYY'))))
       
     //  
       //handleCheckSupervisor();
       if(statesTerritory.length > 0) {
            handleTerritory(employeeData[0].territory)
        }
       

    }, [])

    
    
    

    // dispatch actions
    const dispatch = useDispatch();
     const editUser = (user) => dispatch (editarUsuario(user));
    let data = {
            reportType:1,
            manager: userInfo.userInfo.manager,
            companyManager: userInfo.userInfo.company
        }
        const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));
        
    
    // Add new PTO
    const submitEditUser = e => {
      e.preventDefault();
    //  validarFormulario();
      // Preguntar al usuario
      Swal.fire({
        title: 'Request Summary',
        text: "This is your summary request, are you sure to submit ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        html: '<div>'+
                    '<p> Name : ' + employeeName + ' '+ employeeLastname+'</p>' +
                 '</div>'
      }).then((result) => {
            if (result.value) {
            Swal.fire(
                'Saved',
                'Your request has been sent successfully',
                'success'
            )
        
            const formatHireDate = moment(hireDate).format('YYYY-MM-DD');
            const formatLastIncrement = moment(lastIncrement).format('YYYY-MM-DD');
            const formatBirthDate = moment(birthdate).format('YYYY-MM-DD');
            let formatTerminateDate = '';
            if(terminated) {
                formatTerminateDate = moment(terminateDate).format('YYYY-MM-DD');
            }else {
                formatTerminateDate = null;
            }
                editUser({
                    imgPath,
                    employeeName,
                    employeeLastname,
                    username,
                    company,
                    department,
                    supervisor,
                    email,
                    availableHours,
                    carryOver,
                    sickDays,
                    emailSupervisor,
                    uidEmployee,
                    token,
                    formatHireDate,
                    formatLastIncrement,
                    territory,
                    travelBenefits,
                    terminated,
                    formatTerminateDate,
                    accruedTimeSick,
                    carryOverSickTime,
                    idDepartment,
                    gender,
                    parenthood,
                    workplace,
                    formatBirthDate,
                    birthplace,
                    jobTitle,
                    phone,
                    independetContractor,
                    insperityEmployeeId,
                    salesTeam
                });

                
                let data = {
                    uid : employeeData[0].uid,
                    hours_before_mov:parseInt(employeeData[0].available_hours),
                    hours_after_mov:parseInt(availableHours),
                    hours_mov: (parseInt(employeeData[0].available_hours) - parseInt(availableHours)) + (parseInt(employeeData[0].sick_days_limit) - parseInt(sickDays)) + (parseInt(employeeData[0].carry_over) - parseInt(carryOver)) + (parseInt(employeeData[0].carry_over_sick_time) - parseInt(carryOverSickTime)),
                    carry_over_before_mov:parseInt(employeeData[0].carry_over),
                    carry_over_after_mov:carryOver,
                    carry_over_sick_time_before:parseInt(employeeData[0].carry_over_sick_time),
                    carry_over_sick_time_after:carryOverSickTime,
                    observations: `${employeeData[0].available_hours},${availableHours},${employeeData[0].carry_over},${carryOver},${employeeData[0].sick_days_limit},${sickDays},${employeeData[0].carry_over_sick_time},${carryOverSickTime}`,
                    type_mov: 'Edit User',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:formatLastIncrement,
                    yearly_increase_date_before_mov:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD')
                }
                axiosHr.post('/hour-movement-log', data)
                .then(respuesta => {
                    console.log(respuesta)
                    loadEmployeesManager();
                    //redirect
                    setTimeout(() => {
                        history.goBack();
                    }, 2000);
                    
                })
                .catch(error => {
                console.log(error)
                });
                
                
               
                //history.push('/pto/manager');
            }
        })
      
      
    }

    return (
        <div className="row justify-content-center mt-5">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-body">
                        <h2 className="text-center mb-4 font-weight-bold ">Edit User</h2>
                        <form onSubmit={submitEditUser}>
                            <div className="form-group">
                                <div className="col-sm-12 col-md-4 card-avatar-container mb-3">
                                    {/* Change avatar dinamically */}
                                    <img src={imgSrc} className="card-img" alt="avatar" />
                                </div>
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="customFile" onChange={fileChangedHandler}/>
                                    <label className="custom-file-label" htmlFor="customFile">{chooseText}</label>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Employee Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Employee Name"
                                    value={employeeName}
                                    onChange={handleChangeInput}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Lastname</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Lastname"
                                    value={employeeLastname}
                                    onChange={handleChangeLastName}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Job Title</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Job Title"
                                    value={jobTitle}
                                    onChange={handleChangeJobTitle}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Gender</label>
                                <select className="custom-select"
                                 onChange={e=>saveGender(e.target.value)}
                                 required
                                >
                                    <option value={gender}>{gender}</option>
                                    <option value="">Select gender...</option>
                                    <option value="Female">Female</option>
                                    <option value="Male">Male</option>                                        
                                </select>
                            </div>

                            <div className="form-group">
                                <p><label>Parenthood</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch3" onChange={e => handleCheckParenthood(e.target.value) } 
                                    checked= {parenthood}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch3">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Work Place</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Work Place"
                                    value={workplace}
                                    onChange={e=>saveWorkplace(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Birth Date:</label>
                                        {/* <input 
                                        type="date" 
                                        className="form-control"
                                        onChange={e=>saveDayBeginning(e.target.value)}
                                        required
                                        ref = {dayBeginingRef}
                                        /> */}
                                        <DatePicker
                                        className="datePicker userdatepicker"
                                        todayButton="Today"
                                        selected={birthdate}
                                        onChange={date => saveBirthDate(date)}
                                        placeholderText="Select birth date"
                                       // minDate={new Date()}
                                        />

                                    </div>
                                </div>

                            <div className="form-group">
                                <label>Birth Place</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Birth Place"
                                    value={birthplace}
                                    onChange={e=>saveBirthPlace(e.target.value)}
                                    required
                                />
                            </div>


                            <div className="form-group">
                                <label>User Name</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Username"
                                    value={username}
                                    onChange={e=>saveUserName(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="form-group">
                                <label>Company</label>
                                <select className="custom-select"
                                 onChange={e=>saveCompany(e.target.value)}
                                 required
                                >
                                    <option value={company}>{company}</option>
                                    <option value="CSI">CSI</option>
                                    <option value="Hit Sales & Marketing">Hit Sales & Marketing</option>
                                    <option value="Mexilink">Mexilink</option>
                                    
                                    
                                </select>
                            </div>

                            <div className="form-group">
                                <label>Department</label>
                                <select className="custom-select"
                                 onChange={e=>handleDepartmentSelect(e.target.value)}
                                 required
                                >
                                    <option value={department}>{department}</option>
                                    {departments.map((department, index) =>(
                                                   <option key={index} value={department.department}>{department.department}</option>
                                                ))}
                                </select>
                            </div>

                            <div className="form-group">
                                <p><label>Supervisor</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" onChange={e => handleCheckSupervisor(department) } 
                                    checked= {supervisor}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch2">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <label>Email</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email"
                                    onChange={ e => saveEmail(e.target.value) }
                                    required
                                    value={email}
                                />
                            </div>

                            <div className="form-group ">
                                <label>Email Supervisor</label>
                                <input 
                                    type="email" 
                                    className="form-control" 
                                    placeholder="Email Supervisor"
                                    onChange={ e => saveEmailSupervisor(e.target.value) }
                                    value= {emailSupervisor}
                                    required
                                />
                            </div>

                            <div className='form-group'>
                                <label>Phone Number:</label>
                                    <PhoneInput
                                    country="US"
                                    defaultCountry='US'
                                    value={phone}
                                    onChange={savePhone} />
                                </div>

                            <div className="form-group">
                                <label>MXL Portal User ID</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="UID"
                                    onChange={ e => saveUid(e.target.value) }
                                    required
                                    value={uid}
                                />
                            </div>

                            <div className="form-group">
                                <label>Employee ID</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="InsperityEmployeeId"
                                    onChange={ e => saveInsperityEmployeeId(e.target.value) }
                                    
                                    value={insperityEmployeeId || ''}
                                />
                            </div>

                            <div className="form-group">
                                <label>MXL Portal Token</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Token"
                                    onChange={ e => saveToken(e.target.value) }
                                    required
                                    value={token}
                                />
                            </div>

                            <div className="form-group">
                                <label>Available Hours</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Available Hours"
                                    onChange={ e => saveAvailableHours(e.target.value) }
                                    required
                                    value={availableHours}
                                />
                            </div>

                            <div className="form-group">
                                <label>Carry Over</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Carry Over"
                                    onChange={ e => saveCarryOver(e.target.value) }
                                    required
                                    value={carryOver}
                                />
                            </div>

                            <div className="form-group">
                                <label>Sick Days (Hours)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Sick Days"
                                    onChange={ e => saveSickDays(e.target.value) }
                                    required
                                    value={sickDays}
                                />
                            </div>

                            <div className="form-group">
                                <label>Carry Over Sick Days Carry (Hours)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Carry Over Sick Days"
                                    onChange={ e => saveCarryOverSickTime(e.target.value) }
                                    required
                                    value={carryOverSickTime}
                                />
                            </div>

                            <div className="form-group">
                                <label>Hire Date:</label>
                                {/* <input 
                                type="date" 
                                className="form-control"
                                onChange={e=>saveDayBeginning(e.target.value)}
                                required
                                ref = {dayBeginingRef}
                                /> */}
                                <DatePicker
                                className="datePicker userdatepicker"
                                todayButton="Today"
                                selected={hireDate}
                                onChange={date => saveHireDate(date)}
                                filterDate={isWeekday}
                                placeholderText="Select a weekday"
                                // minDate={new Date()}
                              //  excludeDates={excludedDatesArray}
                                />

                            </div>

                            <div className="form-group">
                                <label>Last Increment:</label>
                                {/* <input 
                                type="date" 
                                className="form-control"
                                onChange={e=>saveDayBeginning(e.target.value)}
                                required
                                ref = {dayBeginingRef}
                                /> */}
                                <DatePicker
                                className="datePicker userdatepicker"
                                todayButton="Today"
                                selected={lastIncrement}
                                onChange={date => saveLastIncrement(date)}
                                filterDate={isWeekday}
                                placeholderText="Select a weekday"
                                // minDate={new Date()}
                              //  excludeDates={excludedDatesArray}
                                />

                            </div>
                         
                            

                            <div className="form-group">
                                <label>Territory</label>
                                <select className="custom-select"
                                 onChange={e=>handleTerritory(e.target.value)}
                                 required
                                >
                                   <option value={territory}>{territory}</option>
                                        {
                                        statesTerritory.map((state, index) =>(
                                            <option key={index} value={state.name}>{state.name}</option>
                                        ))
                                        }
                                </select>
                            </div>        

                            <div className="form-group">
                                <label>Accrued Time Off (Sick Days Plan)</label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="Sick Days Plan"
                                    value = {accruedTimeSick}
                                    onChange={ e => saveAccruedTimeSick(e.target.value) }
                                    required
                                />
                            </div>             

                            <div className="form-group">
                                <p><label>Travel Benefits</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" onChange={e => handleCheckTravelBenefits(e.target.value) } 
                                    checked= {travelBenefits}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch1">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <p><label>Independent Contractor</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch9" onChange={e => handleCheckIndependentContractor(e.target.value) } 
                                    checked= {independetContractor}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch9">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <p><label>Executive / Sales Team</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch39" onChange={e => handleCheckSalesTeam(e.target.value) } 
                                    checked= {salesTeam}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch39">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group">
                                <p><label>Terminate</label></p>
                                <div className="custom-control custom-switch custom-control-inline">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch5" onChange={e => handleCheckTerminate(e.target.value) } 
                                    checked= {terminated}
                                    />
                                    <label className="custom-control-label" htmlFor="customSwitch5">No / Yes</label>
                                </div>
                            </div>

                            <div className="form-group mb-3"
                                ref={terminatedDateRef}
                            >
                                <label>Termination Date:</label>
                                {/* <input 
                                type="date" 
                                className="form-control"
                                onChange={e=>saveDayBeginning(e.target.value)}
                                required
                                ref = {dayBeginingRef}
                                /> */}
                                <DatePicker
                                className="datePicker userdatepicker"
                                todayButton="Today"
                                selected={terminateDate}
                                onChange={date => saveTerminateDate(date)}
                                filterDate={isWeekday}
                                placeholderText="Select a weekday"
                                // minDate={new Date()}
                               // excludeDates={excludedDatesArray}
                                />

                            </div>
                            

                            <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4">Submit</button>
                        </form>
        
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditUser;