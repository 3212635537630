import React, {useState, useEffect, Fragment} from 'react';
import EmployeesTranslateHours from './EmployeesTranslateHours';
import moment from 'moment';
import {editarUsuario} from '../actions/authenticateActions';
import axiosHr from '../config/axiosHR';
import {obtenerEmpleadosManager} from '../actions/manageActions';

//Redux
import { useDispatch, useSelector, useStore } from 'react-redux';

const TranslateHours = ({history}) => {


    //acceder al state
        const managerEmployees = useSelector(state=>state.manager.employees);
        const userInfo = useSelector(state=>state.userInfo)   
        
        const sortByName = (array) => {
            return array.sort(function(a,b) {
                let x = a.employee_name.toLowerCase();
                let y = b.employee_name.toLowerCase();
    
                if(x>y) {return 1;}
                if(x<y) {return -1;}
    
                return 0;
            });
        }

        
        //const employees = userInfo.userHour;
        let employees = managerEmployees;
        employees = sortByName(employees);
        const dispatch = useDispatch();
        const editUser = (user) => dispatch (editarUsuario(user));
        let data = {
            reportType:1,
            manager: userInfo.userInfo.manager,
            companyManager: userInfo.userInfo.company
        }
        const loadEmployeesManager = ()=>dispatch(obtenerEmpleadosManager(data));

    //State para el loading
    const [loading, saveLoading] = useState(false);

         // Crear reglas de incremento.
     const incrementRules = (employee) => {
        let newAvailableHours = employee.available_hours;
        let newCarryOver = employee.carry_over;
        let newSickDays = employee.sick_days_limit;
        let newCarryOverSickDays = employee.carry_over_sick_time;
       // let hireDate = moment.utc(employee.hire_date);

        let seniority = (Math.floor((moment([employee.hire_date, 0]).diff(moment(), 'year') * -1)/(3)) * 8) >= 41 ? 40 : (Math.floor((moment([employee.hire_date, 0]).diff(moment(), 'year') * -1)/(3)) * 8);
        let yearsSeniority = Math.floor((moment([employee.hire_date, 0]).diff(moment(), 'year') * -1));
        let seniorityCSI = 0;
        // Reglas de incremento.

        

        //console.log(seniority , 'seniority' , employee.hire_date, 'hire date', employee.employee_name, 'employee name');
       
        

        if(employee.company === 'CSI'){

            // CSI RULES
            console.log('CSI RULES');
            

        switch (yearsSeniority) {
            case 1:
                newAvailableHours = 120;
                break;
            case 2:
                newAvailableHours = 120;
                break;
            case 3:
                newAvailableHours = 128;
                break;
            case 4:
                newAvailableHours = 144;
                break;
            case 5:
                newAvailableHours = 160;
                break;
            case 6:
                newAvailableHours = 176;
                break;
            case 7:
                newAvailableHours = 176;
                break;
            case 8:
                newAvailableHours = 176;
                break;
            case 9:
                newAvailableHours = 176;
                break;
            case 10:
                newAvailableHours = 176;
                break;
            case 11:
                newAvailableHours = 192;
                break;
            case 12:
                newAvailableHours = 192;
                break;
            case 13:
                newAvailableHours = 192;
                break;
            case 14:
                newAvailableHours = 192;
                break;
            case 15:
                newAvailableHours = 192;
                break;
            case 16:
                newAvailableHours = 208;
                break;
            case 17:
                newAvailableHours = 208;
                break;
            case 18:
                newAvailableHours = 208;
                break;
            case 19:
                newAvailableHours = 208;
                break;
            case 20:
                newAvailableHours = 208;
                break;
            case 21:
                newAvailableHours = 224;
                break;
            case 22:
                newAvailableHours = 224;
                break;
            case 23:
                newAvailableHours = 224;
                break;
            case 24:
                newAvailableHours = 224;
                break;
            case 25:
                newAvailableHours = 224;
                break;
            case 26:
                newAvailableHours = 240;
                break;
            case 27:
                newAvailableHours = 240;
                break;
            case 28:
                newAvailableHours = 240;
                break;
            case 29:
                newAvailableHours = 240;
                break;
            case 30:
                newAvailableHours = 240;
                break;
        
            default:
               
                break;
        }

        //console.log(yearsSeniority , 'yearsseniority' , employee.hire_date, 'hire date', employee.employee_name, 'employee name', 'newAvailableHours', newAvailableHours);
           
            newAvailableHours = newAvailableHours + employee.available_hours;
            newSickDays = 0;
            newCarryOverSickDays = 0;
            newCarryOver = 0;

            return {
                newAvailableHours,
                newCarryOver,
                newSickDays,
                newCarryOverSickDays,
                seniority
            }
        } else {

            //travel benefits
        employee.sales_team === 1 ? newAvailableHours = 136 : newAvailableHours = 96 + seniority; 

        if(employee.available_hours > 40){
            newCarryOver = 40;
        }else {
            newCarryOver = employee.available_hours;
        }

        if(employee.territory === 'California'){
            newSickDays = 48;

            if(employee.carry_over_sick_time > 72){
                newCarryOverSickDays = 72;
            }else {
                newCarryOverSickDays = employee.sick_days_limit;
            }
        }else {
            newSickDays = 24;
            newCarryOverSickDays = 0;
        }

            return {
                newAvailableHours,
                newCarryOver,
                newSickDays,
                newCarryOverSickDays,
                seniority
            }
        }
       

        

        

        
    }

    const updateUserInfo = (employees) => {
    
        employees.forEach(employee => {
            
        
            const formatHireDate = moment(employee.hire_date).format('YYYY-MM-DD');
            const formatLastIncrement = moment(employee.increase_date).format('YYYY-MM-DD');
            const formatBirthDate = moment(employee.birth_date).format('YYYY-MM-DD');
            let formatTerminateDate = '';
            if(employee.terminate_contract === 1){
                formatTerminateDate = moment(employee.terminate_date).format('YYYY-MM-DD');
            }else {
                formatTerminateDate = null;
            }
            let imgPath = employee.avatar;
            let employeeName = employee.employee_name;
            let employeeLastname = employee.employee_lastname;
            let username = employee.username;
            let company = employee.company;
            let department = employee.department;
            let supervisor = employee.supervisor;
            let email = employee.email;
            let availableHours = incrementRules(employee).newAvailableHours;
            let carryOver = incrementRules(employee).newCarryOver;
            let sickDays = incrementRules(employee).newSickDays;
            let emailSupervisor = employee.supervisor_mail;
            let uidEmployee = employee.uid;
            let token = employee.token;
            let territory = employee.territory;
            let travelBenefits = employee.travel_benefits;
            let terminated = employee.terminate_contract;
            let accruedTimeSick = employee.accrued_sick_days;
            let carryOverSickTime = incrementRules(employee).newCarryOverSickDays;
            let idDepartment = employee.id_department;
            let gender = employee.gender;
            let parenthood = employee.parenthood;
            let workplace = employee.work_place;
            let jobTitle = employee.job_title;
            let phone = employee.phone;
            let independetContractor = employee.independent_contractor;
            let insperityEmployeeId = employee.insperity_employee_id;
            let birthplace = employee.birth_place;
            let salesTeam = employee.sales_team;

            
                editUser({
                    imgPath,
                    employeeName,
                    employeeLastname,
                    username,
                    company,
                    department,
                    supervisor,
                    email,
                    availableHours,
                    carryOver,
                    sickDays,
                    emailSupervisor,
                    uidEmployee,
                    token,
                    formatHireDate,
                    formatLastIncrement,
                    territory,
                    travelBenefits,
                    terminated,
                    formatTerminateDate,
                    accruedTimeSick,
                    carryOverSickTime,
                    idDepartment,
                    gender,
                    parenthood,
                    workplace,
                    formatBirthDate,
                    birthplace,
                    jobTitle,
                    phone,
                    independetContractor,
                    insperityEmployeeId,
                    salesTeam
                });

                    
            let data = {
                uid : employee.uid,
                hours_before_mov:parseInt(employee.available_hours),
                hours_after_mov:incrementRules(employee).newAvailableHours,
                hours_mov: incrementRules(employee).newAvailableHours - parseInt(employee.available_hours),
                carry_over_before_mov:parseInt(employee.carry_over),
                carry_over_after_mov:incrementRules(employee).newCarryOver,
                carry_over_sick_time_before:parseInt(employee.carry_over_sick_time),
                carry_over_sick_time_after:incrementRules(employee).newCarryOverSickDays,
                observations: `${employee.available_hours},${incrementRules(employee).newAvailableHours},${employee.carry_over},${incrementRules(employee).newCarryOver},${employee.sick_days_limit},${incrementRules(employee).newSickDays},${employee.carry_over_sick_time},${carryOverSickTime}`,
                type_mov: 'Edit User',
                date_mov: moment().format('YYYY-MM-DD'),
                mov_increase_date: moment(employee.increase_date).format('YYYY-MM-DD'),
                mov_yearly_increase_date:moment(employee.year_increase_date).format('YYYY-MM-DD'),
                increase_date_before_mov:formatLastIncrement,
                yearly_increase_date_before_mov:moment(employee.year_increase_date).format('YYYY-MM-DD')
            }
            axiosHr.post('/hour-movement-log', data)
            .then(respuesta => {
                console.log(respuesta)
            })
            .catch(error => {
            console.log(error)
            });

        });

        loadEmployeesManager();

        //redirect
        setTimeout(() => {

        history.push('/home');
        }, 3000);
    }


    return (
        <Fragment>
            <h1 className="text-center my-5">Translate Hours</h1>

            <div className='card mt-5'>
                <div style={{background:'#004185', color:'white'}} className='card-header'>
                    <h4>Employees Hours Translate Rules</h4>
                </div>
                {userInfo.userInfo.company === 'CSI' ?
                <div className='card-body mt-2 '>
                <ol className='list-group list-group-flush'>
                    <li className='list-group-item'>Employees starts with 120 available hours</li>
                    <li className='list-group-item'>Users Gets 8 hours every 3 years since year of hire date</li>
                   
                    {/* <li className='list-group-item'>You Can Take PTO After 30 Days of Employment (New hired)</li> */}
                </ol>
                <table className='table table-responsive table-striped table-to-card-employees mt-5'>
                        <thead>
                            <tr>
                                <th>Year Worked</th>
                            <th>Initial Available Hours</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>120</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>120</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>128</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>144</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>160</td>
                            </tr>
                            <tr>
                                <td>6-10</td>
                                <td>176</td>
                            </tr>
                            <tr>
                                <td>11-15</td>
                                <td>192</td>
                            </tr>
                            <tr>
                                <td>16-20</td>
                                <td>208</td>
                            </tr>
                            <tr>
                                <td>21-25</td>
                                <td>224</td>
                            </tr>
                            <tr>
                                <td>26-30</td>
                                <td>240</td>
                            </tr>

                        </tbody>
                        </table>
            </div>
            :
            <div className='card-body mt-2 '>
                    <ol className='list-group list-group-flush'>
                        <li className='list-group-item'>Employees starts with 96 available hours</li>
                        <li className='list-group-item'>Users Gets 8 hours every 3 years since year of hire date</li>
                        <li className='list-group-item'>Max Carry Over is 40 hours</li>
                        <li className='list-group-item'>Initial Sick Time 24 hours</li>
                        <li className='list-group-item'>Initial Sick Time California 48 Hours</li>
                        <li className='list-group-item'>California Max Carry Over Sick Time 72 Hours</li>
                        <li className='list-group-item'>Sales Team Starts With 136 Available Hours</li>
                        {/* <li className='list-group-item'>You Can Take PTO After 30 Days of Employment (New hired)</li> */}
                    </ol>
                </div>
                
                }
                

                <div className='card-footer'>
                    <button onClick={() => updateUserInfo(employees)} className='btn btn-warning btn-lg' disabled={loading}>
                        {loading ? 'Processing...' : 'Start Process'}
                    </button>
                    {loading && <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: '100%' }}></div>
                    </div>}
                        
                </div>  
            </div>
        <table className="table table-striped table-to-card-employees mt-5">
                     <thead className="bg-primary table-dark">
                         <tr>
                             <th scope="col">Employee</th>
                             <th scope="col">Department</th>
                             <th scope="col">Hire Date</th>
                             
                             <th scope="col">Available Hours</th>
                            
                             <th scope="col">Carry Over</th>
                             <th scope="col">Sick Days</th>
                             <th scope="col">Carry Over Sick Days</th>
                            <th>Translate Hours To</th>
                            <th scope="col">New Available Hours</th>
                            <th scope="col">Seniority Hours (already included)</th>
                             <th scope="col">New Carry Over</th>
                             <th scope="col">New Sick Days</th>
                             <th scope="col">New Carry Over Sick Days</th>

                             <th scope="col">Actions</th>
                             
                         </tr>   
                     </thead>
                     <tbody>
                         {
                         employees.length > 0
                         ?
                            employees.map(employee =>(
                                <EmployeesTranslateHours
                                key={employee.id}
                                employee = {employee}
                                incrementRules = {incrementRules}
                                />
                                
                            ))
                         :
                                
                            null
                         }
                     </tbody>
                 </table>
        </Fragment>
    );
};

export default TranslateHours;