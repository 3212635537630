import React , {Fragment, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';


const Navigation = ({history}) => {
    //acceder al state
    const supervisor = useSelector(state=>state.userInfo.userInfo.supervisor);
    const manager = useSelector(state=>state.userInfo.userInfo.manager);
    const userEmail = useSelector(state=>state.userInfo.userInfo.email);
    const hireDate = useSelector(state=>state.userInfo.userInfo.hireDate);

    const [pendingPto, savePendingPto] = useState(0);
    const [loginBar, saveloginBar] = useState(true);
    const countPto = useSelector(state=>state.ptoList.countPto);
        useEffect(()=>{
            savePendingPto(countPto);
            saveloginBar(true)

        },[countPto])


    const [ptoAuth, savePtoAuth] = useState(false);

    useEffect(()=>{
        const daysDifference = moment().diff(moment(hireDate), 'days');

        moment().diff(moment(hireDate), 'days') < 30 ? savePtoAuth(false) : savePtoAuth(true);
        console.log('daysDifference', daysDifference, 'hire date', hireDate)
        
    },[hireDate])
        
    const logout = ()=> {
        localStorage.clear();

        saveloginBar(false);

        window.location.reload(false);
     
    }

    

    return (
        supervisor !== undefined ? (
            <nav className="navbar navigation-bar-menu navbar-expand-lg navbar-dark bg-primary justify-content-between"> 
                <div className="container">
                        <input type="checkbox" className="openSidebarMenu" id="openSidebarMenu" />
                    <label htmlFor="openSidebarMenu" className="sidebarIconToggle">
                        <div className="spinner diagonal part-1"></div>
                        <div className="spinner horizontal"></div>
                        <div className="spinner diagonal part-2"></div>
                        {
                            pendingPto > 0 ? <span className="badge badge-pill badge-danger ml-3">{pendingPto}</span> : null
                        }
                        
                    </label>
                    
                    <div id="sidebarMenu" className='sidebar-content'>
                        <ul className="sidebarMenuInner">

                            { (manager)
                            ? 
                            <Fragment>
                            <li className="mega-menu">
                                <Link to={'/pto/manager'} className="nuevo-pto d-block sm-ml-3">
                                    H.R. MANAGER
                                    <span className="navigation-subtitle">Human Resources</span>
                                </Link>
                                <div className="mega-menu-content">
                                    <Link to={`/pto/calendar/${moment().format('YYYY-MM-DD')}`} className="nuevo-pto d-block sm-ml-3">
                                        MONTHLY CALENDAR VIEW
                                        <span className="navigation-subtitle">CURRENT MONTH</span>
                                    </Link>
                                    <Link to={'/pto/year-calendar'} className="nuevo-pto d-block sm-ml-3">
                                        FULL YEAR CALENDAR VIEW
                                        <span className="navigation-subtitle">PTO CALENDAR</span>
                                    </Link>
                                </div>
                            </li>
                        </Fragment>    
                            :
                                null
                            }
                            { (supervisor && !manager)
                            ? 
                                <Fragment>
                                 <li className='mega-menu'>
                                    <Link to={'/pto/manager-supervisor'} className="nuevo-pto d-block sm-ml-3">
                                        SUPERVISOR MANAGER
                                        <span className="navigation-subtitle">DEPARTMENT INFO</span>
                                    
                                </Link>
                                    <div className='mega-menu-content'>
                                <Link to={`/pto/calendar/${moment().format('YYYY-MM-DD')}`} className="nuevo-pto d-block sm-ml-3">
                                    <li>CALENDAR VIEW
                                        <span className="navigation-subtitle">CURRENT MONTH</span>
                                    </li>
                                </Link> 
                                <Link to={'/pto/year-calendar'} className="nuevo-pto d-block sm-ml-3">
                                    <li>FULL YEAR CALENDAR VIEW
                                        <span className="navigation-subtitle">PTO CALENDAR</span>
                                    </li>
                                </Link> 
                                </div>
                            </li>
                                </Fragment>    
                            :
                                null
                            }

                        <Fragment>
                        <li className='mega-menu'>
                            <Link to={'/home'} className="nuevo-pto d-block sm-ml-3">
                                
                                    PTO Panel
                                    <span className="navigation-subtitle">Your PTO Request</span>
                                
                            </Link>

                                <div className='mega-menu-content'>
                                    { (supervisor)
                                    ? 
                                        <Link to={'/pto/supervisor'} className="nuevo-pto d-block sm-ml-3">
                                            <li>PENDING PTO  
                                                { pendingPto > 0 ? <span className="badge badge-pill badge-danger ml-2">{pendingPto}</span> : null}
                                                
                                                <span className="navigation-subtitle">From Your Department</span>
                                            
                                            </li>
                                            
                                        </Link>
                                    :
                                        null
                                    }

                                

                                
                                    {
                                    (ptoAuth === true) 
                                    ? 
                                    <Link to={'/pto/new'} className="nuevo-pto d-block sm-ml-3">
                                        <li> 
                                            NEW PTO
                                            <span className="navigation-subtitle">Create a new PTO Request</span>
                                            
                                        </li>
                                    </Link>
                                        :
                                        
                                        <li>
                                            
                                            <span className="alert alert-warning alert-sm navigation-subtitle" style={{fontSize:'12px', color:'white'}}>You need 30 days as an employee to request pto </span>
                                        </li>
                                        
                                    }
                                        
                                    

                                    
                                    
                                
                                </div>
                            </li>
                        </Fragment>
                        <Fragment>
                            { (userEmail == 'valeria.dequintal@mexilink.com' || userEmail == 'julio.castro@mexilink.com' || userEmail == 'maria.chacin@mexilink.com' || userEmail == 'cynthia.ramos@mexilink.com' || userEmail == 'jonathan.lareo@mexilink.com' || userEmail == 'eduardo.jimenez@hitsalesmarketing.com' || userEmail == 'sabrina.cartolano@mexilink.com' || userEmail == 'alfonso.montero@mexilink.com')
                            ?
                                <li className='mega-menu'> 
                                    <Link to={'/fixed-asset-request-panel'} className="nuevo-pto d-block sm-ml-3">
                                    
                                            Fixed Asset Request Admin Panel
                                            <span className="navigation-subtitle">Fixed Asset Request List</span>
                                    
                                    </Link>
                                    <div className='mega-menu-content'>
                                    <Link to={'/fixed-asset-request/new'} className="nuevo-pto d-block sm-ml-3">
                                        <li> 
                                            Fixed Asset
                                            <span className="navigation-subtitle">Create a new request</span>
                                        </li>
                                    </Link>
                                    </div>
                                </li>
                            :
                                null
                            }
                        </Fragment>
                        <Fragment>
                            { (userEmail == 'julio.castro@mexilink.com' || userEmail == 'ventas.internal18@mexilink.com' || userEmail == 'maria.chacin@mexilink.com' || userEmail == 'nubia.osuna@mexilink.com')
                            ?
                                <li className='mega-menu'> 
                                    <Link to={'/personal-request-panel'} className="nuevo-pto d-block sm-ml-3">
                                    
                                            Recruitment Request Admin Panel
                                            <span className="navigation-subtitle">Recruitment Request List</span>
                                    
                                    </Link>
                                    <div className='mega-menu-content'>
                                    <Link to={'/personal-request/new'} className="nuevo-pto d-block sm-ml-3">
                                        <li> 
                                            Recruitment Request
                                            <span className="navigation-subtitle">Create a new request</span>
                                        </li>
                                    </Link>
                                    </div>
                                </li>
                            :
                                null
                            }
                        </Fragment>
                            { (userEmail == 'astrid.miranda@mexilink.com' || userEmail == 'julio.castro@mexilink.com' || userEmail == 'jonathan.lareo@mexilink.com')
                            ?
                            <Link to={'/user-reports'} className="nuevo-pto d-block sm-ml-3">
                                <li> 
                                    Reports
                                    <span className="navigation-subtitle">UsersReports</span>
                                </li>
                            </Link>
                            :
                            null
                            }
                            <li onClick={()=> logout()}>
                                    <Link to={'/'}  className="text-light">
                                        Logout
                                        <span className="navigation-subtitle"></span>
                                    </Link>
                            </li>   
                        </ul>
                    </div>
                    <h1>
                        <Link to={'/'} className="text-light">
                            <img src="../../mxllog.png" alt="Logo" className="img-fluid logo" />
                        </Link>
                    </h1>
                    
                </div>
            </nav>
        ) 
        : (
            <nav className="navbar navbar-expand-lg navbar-dark bg-primary justify-content-between">
                <div className="container">
                    
                    <h1>
                        <Link to={'/'} className="text-light">
                        <img src="../../mxllog.png" alt="Logo" className="img-fluid logo" />
                        </Link>
                    </h1>
                </div>

            </nav>
        )
            
    );
};

export default Navigation;