import React, {useState, useEffect, useRef} from 'react';
//import ReactDom from 'react-dom';

// Redux
import { validarFormularioAction, validacionExito, validacionError } from '../actions/validacionActions';
import {createNewPtoAction} from '../actions/timeoffrequestActions';
import {ajustarBalanceHoras} from '../actions/authenticateActions';
import {obtenerDepartamentos} from '../actions/manageActions';
import { useDispatch, useSelector } from 'react-redux';

// library
import SignatureCanvas from 'react-signature-canvas';
import moment from 'moment-business-days';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import axiosHr from '../config/axiosHR';
 
import "react-datepicker/dist/react-datepicker.css";

const NewPtoHr = ({match, history}) => {

     // obtener el ID a editar
     const { emp_uid } = match.params;

     var holidaysArray = ['04-02-2021', '05-31-2021', '07-05-2021', '09-06-2021', '11-25-2021', '11-26-2021', '12-23-2021', '12-24-2020', '01-01-2021', '01-01-2022', '04-15-2022', '05-30-2022', '07-04-2022', '09-05-2022', '11-24-2022', '11-25-2022', '12-23-2022', '12-26-2022', '01-02-2023', '04-07-2023', '07-04-2023', '05-29-2023', '09-04-2023', '11-23-2023', '11-24-2023', '12-22-2023', '12-25-2023', '01-01-2024', '03-29-2024', '05-27-2024', '07-04-2024', '09-02-2024', '11-28-2024', '11-29-2024', '12-24-2024', '12-25-2024', '01-01-2025', '04-18-2025', '05-26-2025', '07-04-2025', '09-01-2025', '11-27-2025', '11-28-2025', '12-24-2025', '12-25-2025', '01-01-2026'];

     var excludedDatesArray = [new Date("2021-02-04T12:00:00-00:00"), new Date("2021-05-31T12:00:00-00:00"), new Date("2021-07-05T12:00:00-00:00"), new Date("2021-09-06T12:00:00-00:00"), new Date("2021-11-25T12:00:00-00:00"), new Date("2021-11-26T12:00:00-00:00"), new Date("2021-12-23T12:00:00-00:00"), new Date("2021-12-24T12:00:00-00:00"),new Date("2022-01-01T12:00:00-00:00"), new Date("2021-01-01T12:00:00-00:00"), new Date("2022-04-15T12:00:00-00:00"), new Date("2022-05-30T12:00:00-00:00"), new Date("2022-07-04T12:00:00-00:00"), new Date("2022-09-05T12:00:00-00:00"), new Date("2022-11-24T12:00:00-00:00"), new Date("2022-11-25T12:00:00-00:00"), new Date("2022-12-23T12:00:00-00:00"), new Date("2022-12-26T12:00:00-00:00"), new Date("2023-01-02T12:00:00-00:00"), new Date("2023-04-07T12:00:00-00:00"), new Date("2023-05-29T12:00:00-00:00"), new Date("2023-07-04T12:00:00-00:00"), new Date("2023-09-04T12:00:00-00:00"), new Date("2023-11-23T12:00:00-00:00"), new Date("2023-11-24T12:00:00-00:00"), new Date("2023-12-22T12:00:00-00:00"), new Date("2023-12-25T12:00:00-00:00"), new Date("2024-01-01T12:00:00-00:00"), new Date("2024-03-29T12:00:00-00:00"), new Date("2024-05-27T12:00:00-00:00"), new Date("2024-07-04T12:00:00-00:00"), new Date("2024-09-02T12:00:00-00:00"), new Date("2024-11-28T12:00:00-00:00"), new Date("2024-11-29T12:00:00-00:00"), new Date("2024-12-24T12:00:00-00:00"), new Date("2024-12-25T12:00:00-00:00"), new Date("2025-01-01T12:00:00-00:00"), new Date("2025-04-18T12:00:00-00:00"), new Date("2025-07-04T12:00:00-00:00"), new Date("2025-09-01T12:00:00-00:00"), new Date("2025-11-27T12:00:00-00:00"), new Date("2025-11-28T12:00:00-00:00"), new Date("2025-12-24T12:00:00-00:00"), new Date("2025-12-25T12:00:00-00:00"), new Date("2026-01-01T12:00:00-00:00")];
   

    // set working days
    moment.updateLocale('us', {
        workingWeekdays: [1, 2, 3, 4, 5],
        holidays: holidaysArray,
        holidayFormat: 'MM-DD-YYYY'

     });


    // get data from state
    const error = useSelector((state) => state.error.error);
    //acceder al state
    const userInfo = useSelector(state=>state.userInfo);

    const usersHour = useSelector(state=>state.manager.employees);

    // obtener departamentos
    const departments = useSelector(state=>state.manager.departments);

    const filterDepartment  = (array, query) => {
        return array.filter(function(el) {
            let departmentElement = el.department
            return departmentElement.toLowerCase().indexOf(query.toLowerCase()) > -1;
        })
      }

    // get user general data
    const employeeData = usersHour.filter(user => user.uid == emp_uid);

    // agregar balance de horas
    const balanceHorasActual = employeeData[0];

    const supervisor = employeeData[0].supervisor;

    const supervisorEmail = employeeData[0].supervisor_mail;

    const email = employeeData[0].email;

    // state
    const [employeeName, saveEmployeeName] = useState('');
    const [department, saveDepartment] = useState('');
    const [reason, saveReason] = useState('');
    const [dayBegining, saveDayBeginning] = useState('');
    const [dayEnd, saveDayEnd] = useState('');
    const [timeBeginning, saveTimeBeginning] = useState('');
    const [timeEnd, saveTimeEnd] = useState('');
    const [totalHours, saveTotalHours] = useState('');
    const [absenceCode, saveAbsenceCode] = useState('');
    const [signatureEmployee, saveSignatureEmployee] = useState('');
    const [dateSignEmployee, saveDateSignEmployee] = useState(new Date());
    const [uid, saveUid] = useState('');
    const [hourValidationError, saveHourValidationError] = useState('');
    const [dateValidationError, saveDateValidationError] = useState('');
    const [departmentColor, saveDepartmentColor] = useState('');
    const [carryOver, saveCarryOver] = useState('');
    const [availableHours, saveAvailableHours] = useState('');
    const [carryOverUsage, saveCarryOverUsage] = useState('');
    const [specialCodePlus, saveSpecialCodePlus] = useState(0);
    const [sickHours, saveSickHours] = useState('');
    const [sickHoursUsage, saveSickHoursUsage] = useState('');
    const [carryOverSickHours, saveCarryOverSickHours] = useState('');
    const [carryOverSickHoursUsage, saveCarryOverSickHoursUsage] = useState('');
    //const [signatureSupervisor, saveSignatureSupervisor] = useState('');

  

    const isWeekday = date => {
        const day = moment(date).day();
        return day !== 0 && day !== 6;
      };
    
    // Time Refs
    const dayBeginingRef = useRef('');
    const dayEndRef = useRef('');
    const timeBeginningRef = useRef('');
    const timeEndRef = useRef('');
    const carryOverRef = useRef('');
    const specialCodeRef = useRef('');
    const sickHoursRef = useRef('');
    const carryOverSickHoursRef = useRef('');
   

    // canvas
    
    let sigPadEmployee = {};
    const clearEmployee = (e) => {
        e.preventDefault();
        sigPadEmployee.clear();
      }
    const saveCanvasEmployee = (e)=> {
        e.preventDefault();
        let urlImage = sigPadEmployee.getTrimmedCanvas().toDataURL('image/png');
        saveSignatureEmployee(urlImage);
    }

    const handleChangeInput = (event) => {
        const {value} = event.target;
        let regex = new RegExp("^[a-zA-Z0-9áéíóúÁÉÍÓÚ,. ]+$");

        if(regex.test(value)) {
           // console.log(value);
            saveReason(value);
        } else {
            console.log("Es un caracter no válido")
        }
    }

    const handleChangeReasonCode = (value) => {
        console.log(timeBeginningRef.current.props.disabled);
        dayBeginingRef.current.clear();
        dayEndRef.current.clear();
        saveAbsenceCode(value);
        if(value === 'V') {
            saveSpecialCodePlus(0);
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
            specialCodeRef.current.value = '';
        }
        if(value === 'PT') {
            saveSpecialCodePlus(0);
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
            specialCodeRef.current.value = '';
        }
        if(value === 'S') {
            saveSpecialCodePlus(0);
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
            specialCodeRef.current.value = '';
        }
        if(value === 'O') {
            saveSpecialCodePlus(0);
            specialCodeRef.current.disabled = false;
            if(totalHours < 0) {
                saveTotalHours(0);
            }
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true; 
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
        }else {
            specialCodeRef.current.disabled = true;
        }
        if(value ==='J') {
            saveSpecialCodePlus(0);
          //  saveTotalHours(totalHours - 40);
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='B1') {
            saveSpecialCodePlus(0);
          //  saveTotalHours(totalHours - 8)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
        }
        if(value ==='B2') {
            saveSpecialCodePlus(0);
          //  saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='B3') {
            saveSpecialCodePlus(0)
            // saveTotalHours(totalHours - 40)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='NB') {
            saveSpecialCodePlus(0)
            // saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='NBCSI') {
            saveSpecialCodePlus(0)
            // saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='VT') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = false;
            timeEndRef.current.input.disabled = false;
        }
        if(value ==='M') {
            saveSpecialCodePlus(0)
            //  saveTotalHours(totalHours - 24)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='MA') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='LA') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='LTD') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='STD') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='FFCRA') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        if(value ==='MD') {
            saveSpecialCodePlus(0)
           // saveTotalHours(totalHours - 480)
            specialCodeRef.current.disabled = false;
            timeBeginningRef.current.clear();
            timeEndRef.current.clear();
            timeBeginningRef.current.input.disabled = true;
            timeEndRef.current.input.disabled = true;
        }
        
    }

    // let sigPadSupervisor = {};
    // const clearSupervisor = (e) => {
    //     e.preventDefault();
    //     sigPadSupervisor.clear();
    // }

    // const saveCanvasSupervisor = (e)=> {
    //     e.preventDefault();
    //     let urlImage = sigPadSupervisor.getTrimmedCanvas().toDataURL('image/png');
    //     saveSignatureSupervisor(urlImage);
    // }

    // dispatch actions
    const dispatch = useDispatch();
    const agregarPto = (pto) => dispatch (createNewPtoAction(pto));
    const balanceHoras = (horas) => dispatch(ajustarBalanceHoras(horas));
    const validarFormulario = () => dispatch( validarFormularioAction());
    const exitoValidacion = () => dispatch( validacionExito() ) ;
    const errorValidacion = () => dispatch( validacionError() ) ;


    useEffect( ()=>{

        if(employeeData[0].employee_name !== undefined || employeeData[0].supervisor !== undefined || employeeData[0] !== undefined){
            saveEmployeeName(employeeData[0].employee_name+' '+employeeData[0].employee_lastname);
            saveCarryOver(parseInt(balanceHorasActual.carry_over));
            saveSickHours(parseInt(balanceHorasActual.sick_days_limit));
            saveCarryOverSickHours(parseInt(balanceHorasActual.carry_over_sick_time));
            saveAvailableHours(balanceHorasActual.available_hours);
        } else {
            history.push('/home');
        }

        if(employeeData[0].department !== undefined) {
            saveDepartment(employeeData[0].department);
            let departmentFilter = filterDepartment(departments, employeeData[0].department);
            console.log(departmentFilter)
            saveDepartmentColor(departmentFilter[0].color);
        }

        if(employeeData[0].uid !== undefined) {
            saveUid(employeeData[0].uid);
        }

        //saveTotalHours('0');
        let rangeBeginning = moment(dayBegining);
        let rangeEnd = moment(dayEnd);
        let rangeHourBeginning = moment(timeBeginning, 'HH:mm');
        let rangeHourEnd = moment(timeEnd, 'HH:mm');
        let totalAmount, hourPerDay;
        let launchTimeBeginning =  moment('14:00:00', 'HH:mm');
        let launchTimeEnd = moment('15:00:00', 'HH:mm');
        let laborTimeBeginning =  moment('08:00:00', 'HH:mm');
        let laborTimeEnd = moment('23:00:00', 'HH:mm');
        if(rangeBeginning._locale._invalidDate === '' && rangeEnd._locale._invalidDate === '') {
            
            return
        }else {
            
            let subtractDays, subtractHours;


            if(rangeBeginning._locale._invalidDate === 'Invalid date' && rangeEnd._locale._invalidDate === 'Invalid date') {
                subtractHours = 0;
            }
               
                if ((rangeHourBeginning.isBetween(laborTimeBeginning, launchTimeEnd) && rangeHourEnd.isBetween(launchTimeEnd, laborTimeEnd)) || (rangeHourBeginning.isBetween(launchTimeBeginning, launchTimeEnd) && rangeHourEnd.isBetween(launchTimeBeginning, launchTimeEnd))) {
                    subtractHours = (moment.duration(rangeHourEnd - rangeHourBeginning).asHours()) - 1;
                }else {
                    subtractHours = moment.duration(rangeHourEnd - rangeHourBeginning).asHours();
                }
                //subtractHours = rangeHourEnd.diff(rangeHourBeginning);
                //subtractDays = rangeEnd.diff(rangeBeginning, 'hours');
               
                //subtractDays = moment.duration(rangeEnd - rangeBeginning).asDays();
                if(rangeBeginning.format('YYYY-MM-DD') === rangeEnd.format('YYYY-MM-DD')) {
                    

                    // if(absenceCode === 'B1') {
                    //     subtractDays =  rangeEnd.businessDiff(rangeBeginning) + 1;
                    // }else {
                    //     subtractDays =  rangeEnd.businessDiff(rangeBeginning);
                    // }
                    subtractDays =  (rangeEnd.businessDiff(rangeBeginning));
                    
                }else {
                    subtractDays =  rangeEnd.businessDiff(rangeBeginning) + 1;
                }
                
                hourPerDay = (subtractDays * 8);

               // console.log(subtractDays);
                
                if(isNaN(subtractHours) && !isNaN(hourPerDay)) {
                  //  saveTotalHours(Math.round(hourPerDay - specialCodePlus));
                    saveTotalHours(Math.round(hourPerDay));
                }else if(isNaN(hourPerDay) && isNaN(subtractHours)) {
                    saveTotalHours(0);
                }else {
		            totalAmount = parseInt(hourPerDay) + parseInt(Math.round(subtractHours));
                   // saveTotalHours(totalAmount - specialCodePlus);
                    saveTotalHours(totalAmount);
                }

                if(absenceCode === 'LA' || absenceCode === 'FFCRA' || absenceCode === 'LTD' || absenceCode === 'STD' || absenceCode === 'MD') {
                   // totalAmount = 0;
                   // saveTotalHours(0);
                    totalAmount = parseInt(hourPerDay);
                    saveTotalHours(totalAmount);
                    saveSpecialCodePlus(totalAmount);
                }

                // if(rangeBeginning.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') || rangeEnd.format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') || rangeEnd.format('YYYY-MM-DD') < rangeBeginning.format('YYYY-MM-DD')) {
                if(rangeEnd.format('YYYY-MM-DD') < rangeBeginning.format('YYYY-MM-DD')) {
                    saveTotalHours(0);
                    saveDateValidationError(true);
                }else {
                    saveDateValidationError(false);
                }
                
            
        }

        let carryOverSum, sumAvailableHours, sickHoursSum, carryOverSickSum = 0;
        //console.log(availableHours);
        if(employeeData[0].carryOver !== undefined || employeeData[0].carryOver !== 0 ||employeeData[0].carryOver !== '') {
             let carryoverInit = balanceHorasActual.carry_over;
             let sickHoursInit = balanceHorasActual.sick_days_limit;
             let carryoversickInit = balanceHorasActual.carry_over_sick_time;
            if (totalHours !== '' || totalHours !== undefined) {
                if(specialCodePlus !== 0) {
                    // carryOverSum = (parseInt(carryoverInit) - parseInt(totalHours) + parseInt(specialCodePlus));
                    carryOverSum = (parseInt(carryoverInit) - parseInt(totalHours) + specialCodePlus);
                }else {
                    carryOverSum = (parseInt(carryoverInit) - parseInt(totalHours));
                }
                if(absenceCode === 'S') {
                    carryOverSickSum = (parseInt(carryoversickInit) -parseInt(totalHours));

                    sickHoursSum = ((parseInt(sickHoursInit) + parseInt(carryoversickInit))  - parseInt(totalHours));

                   // sickHoursSum = (parseInt(sickHoursInit) - parseInt(totalHours));

                    saveCarryOver(Math.round(carryoverInit));
                    carryOverRef.current.value = carryoverInit;
                    saveCarryOverUsage(0);

                    if(!isNaN(carryOverSickSum)) {
                        if(carryOverSickSum >= 0) {
                            if(carryOverSickSum < 0) {
                                carryOverSickHoursRef.current.value = '0';
//                                saveCarryOverSickHoursUsage(carryoversickInit)
                                saveCarryOverSickHoursUsage(Math.round(carryoversickInit));
                                saveCarryOverSickHours(Math.round(carryOverSickSum));
                            }else {
                                saveCarryOverSickHours(Math.round(carryOverSickSum));
                                carryOverSickHoursRef.current.value = carryOverSickSum;
                                saveCarryOverSickHoursUsage(carryoversickInit - carryOverSickSum);
                            }

                        }else {

                            if(!isNaN(sickHoursSum)) {
                                if(sickHoursSum < 0) {
                                    carryOverSickHoursRef.current.value = '0';
                                    sickHoursRef.current.value = '0';
                                    saveSickHoursUsage(sickHoursInit)
                                    saveSickHoursUsage(Math.round(sickHoursSum));
                                }else {
                                    carryOverSickHoursRef.current.value = '0';
                                    saveSickHours(Math.round(sickHoursSum));
                                    sickHoursRef.current.value = sickHoursSum;
                                    saveSickHoursUsage(sickHoursInit- sickHoursSum);
                                }
                            }
                        }
                    }


                } else {
                    saveSickHours(sickHoursInit);
                    saveSickHoursUsage(0)
                    saveCarryOverSickHours(carryoversickInit);
                    saveCarryOverSickHoursUsage(0);
                    sickHoursRef.current.value = sickHoursInit;
                    // console.log(carryoverInit + " - " + totalHours + " = " + carryOverSum);
                        if(!isNaN(carryOverSum)) {
                            if(carryOverSum < 0 ) {
                                let availableHoursInit = balanceHorasActual.available_hours
                           // sumAvailableHours = availableHoursInit + carryOverSum + specialCodePlus
                            sumAvailableHours = availableHoursInit + carryOverSum
                            //console.log(sumAvailableHours);
                            saveAvailableHours(sumAvailableHours);
                            }
                            if(carryOverSum < 0) {
                                carryOverRef.current.value = '0';
                                saveCarryOverUsage(carryoverInit);
                                saveCarryOver(Math.round(carryOverSum));
                            }else {
                                saveCarryOver(Math.round(carryOverSum));
                                carryOverRef.current.value = carryOverSum;
                                saveCarryOverUsage(carryoverInit - carryOverSum);
                                
                            }
                        }
                        //console.log("carry Over =", carryOverSum);
                 }
            }
            
        }

        if (parseInt(totalHours) > parseInt(balanceHorasActual.available_hours) + parseInt(balanceHorasActual.carry_over) + parseInt(balanceHorasActual.sick_days_limit)+ parseInt(balanceHorasActual.carry_over_sick_time)) {
            saveHourValidationError(true);
           //saveTotalHours(0);
        }else {
            saveHourValidationError(false);
        }       

    }, [employeeData, dayBegining, dayEnd, timeBeginning, timeEnd, dispatch, userInfo, totalHours, balanceHorasActual.available_hours, history, carryOver, balanceHorasActual.carry_over, specialCodePlus])
    
    // Add new PTO
    const submitNewPto = e => {
      e.preventDefault();
      validarFormulario();
      if (parseInt(totalHours) > (parseInt(balanceHorasActual.sick_days_limit) + parseInt(balanceHorasActual.carry_over_sick_time)) && absenceCode === 'S') {
        saveHourValidationError(true);
        // errorValidacion();
        //saveTotalHours(0);
        dayBeginingRef.current.value = '';
        dayEndRef.current.value = '';
        return
        
        }else {
            saveHourValidationError(false);
        }
      if(employeeName.trim() === ''|| department.trim() === '' || totalHours < 0 || absenceCode.trim() === '' || signatureEmployee.trim() === '') {
          errorValidacion();
          return
      }

      exitoValidacion();

      let absenceText;
      if(absenceCode ==='V') { 
        absenceText = 'Vacation'
      }
      if(absenceCode ==='LA') {
        absenceText = 'Leave of Absence'
      }
      if(absenceCode ==='LTD') {
        absenceText = 'Long Term Disability'
      }
      if(absenceCode ==='STD') {
        absenceText = 'Short Term Disability'
      }
      if(absenceCode ==='PT') { 
        absenceText = 'Personal Time'
      }
      if(absenceCode ==='S') {
        absenceText = 'Sick'
      }
      if(absenceCode ==='J') {
        absenceText = 'Jury Duty'
      }
      if(absenceCode ==='B1') {
        absenceText = 'Bereavement 1'
      }
      if(absenceCode ==='B2') {
        absenceText = 'Bereavement 2'
      }
      if(absenceCode ==='B3') {
        absenceText = 'Bereavement 3'
      }
      if(absenceCode ==='NB') {
        absenceText = 'New Baby Born'
      }
      if(absenceCode ==='NBCSI') {
        absenceText = 'New Baby Born'
      }
      if(absenceCode ==='M') {
        absenceText = 'Marriage'
      }
      if(absenceCode ==='MA') {
        absenceText = 'Maternity'
      }
      if(absenceCode ==='O') {
        absenceText = 'Other'
      }
      if(absenceCode ==='FFCRA') {
        absenceText = 'FFCRA (Emergency Sick Time)'
      }
      if(absenceCode ==='VT') {
        absenceText = 'Vote'
      }

      if(absenceCode ==='MD') {
        absenceText = 'Medical Disability'
      }

      if (parseInt(totalHours) > parseInt(balanceHorasActual.available_hours) + parseInt(employeeData[0].carryOver)) {
        saveHourValidationError(true);
       // saveTotalHours(0);
        dayBeginingRef.current.value = '';
        dayEndRef.current.value = '';
        // timeBeginningRef.current.value = '';
        // timeEndRef.current.value = '';
       // dayBeginingRef.current.focus();
        // saveDayBeginning('');
        // saveDayEnd('');
        // saveTimeBeginning('');
        // saveTimeEnd('');
        return
        
        }else {
        saveHourValidationError(false);
        
        }

      // Preguntar al usuario
      Swal.fire({
        title: 'Request Summary',
        text: "This is your summary request, are you sure to submit ?",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        html: '<div>'+
                    '<p> Name : ' + employeeName + '</p>' +
                    '<p> Department : ' + department + '</p>' +
                  //  '<p> Reason : ' + reason + ' </p>' +
                    '<p> Total Hours: ' + totalHours + '</p>' +
                    '<p> Absence Code: ' + absenceText + '</p>' +
                 '</div>'
      }).then((result) => {
        if (result.value) {
          Swal.fire(
            'Saved',
            'Your request has been sent successfully',
            'success'
          )
          let rangeBeginning = moment(dayBegining).format('YYYY-MM-DD');
          let rangeEnd = moment(dayEnd).format('YYYY-MM-DD');
          let rangeHourBeginning = moment(timeBeginning).format('HH:mm');
          let rangeHourEnd = moment(timeEnd).format('HH:mm');
          let dateSignature = moment(dateSignEmployee).format('YYYY-MM-DD');
          const hrPto = true;

          if(rangeHourBeginning === 'Invalid date' || rangeHourEnd === 'Invalid date') {
            rangeHourBeginning = moment("1200", "hmm").format("HH:mm");
            rangeHourEnd = moment("1200", "hmm").format("HH:mm");
          }
          const company = employeeData[0].company;


            agregarPto({
                employeeName,
                department,
                reason,
                rangeBeginning,
                rangeEnd,
                rangeHourBeginning,
                rangeHourEnd,
                totalHours,
                absenceCode,
                signatureEmployee,
                dateSignature,
                uid,
                supervisor,
                departmentColor,
                email,
                carryOverUsage,
                sickHoursUsage,
                carryOverSickHoursUsage,
                hrPto,
                supervisorEmail,
                company
            });

            // let carryOverZero;

            // if(carryOver < 0) {
            //     carryOverZero = 0;
            // }else {
            //     carryOverZero = carryOver;
            // }

            let carryOverZero, sickHoursZero, carryOverSickZero ;

            if(carryOver < 0) {
                carryOverZero = 0;
            }else {
                carryOverZero = carryOver;
            }

            if(sickHours < 0) {
                sickHoursZero = 0;
            }else {
                sickHoursZero = sickHours;
            }

            if(carryOverSickHours < 0) {
                carryOverSickZero = 0
            }else {
                carryOverSickZero = carryOverSickHours;
            }

            let termDisabilityWeeks = totalHours / 40
            let termDisabilityHours = Math.floor(termDisabilityWeeks) *2

            console.log(termDisabilityWeeks, termDisabilityHours)
// modificar las horas del usuario esto debe ser temporal

            // balanceHoras({
            //     available_hours: parseInt(availableHours),
            //     requested_hours: parseInt(balanceHorasActual.requested_hours) + parseInt(totalHours),
            //     total_requests: balanceHorasActual.total_requests + 1,
            //     carry_over: carryOverZero,
            //     sick_hours_limit: sickHoursZero,
            //     carry_over_sick_time: carryOverSickZero,
            //     increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
            //     typeBalance: 'false',
            //     uid : uid
            // });

            // original pto hr.

            // let data = {
            //     uid : uid,
            //     hours_before_mov:parseInt(employeeData[0].available_hours),
            //     hours_after_mov:parseInt(employeeData[0].available_hours),
            //     hours_mov: parseInt(totalHours),
            //     carry_over_before_mov:parseInt(employeeData[0].carry_over),
            //     carry_over_after_mov:parseInt(employeeData[0].carry_over),
            //     carry_over_sick_time_before:parseInt(employeeData[0].carry_over_sick_time),
            //     carry_over_sick_time_after:parseInt(employeeData[0].carry_over_sick_time),
            //     observations: 'New H.R PTO ' + absenceText,
            //     type_mov: 'New H.R. PTO',
            //     date_mov: moment().format('YYYY-MM-DD'),
            //     mov_increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
            //     mov_yearly_increase_date:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD'),
            //     increase_date_before_mov:moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
            //     yearly_increase_date_before_mov:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD')
            // }
            // 2nd attempt
            let data = {
                uid : uid,
                hours_before_mov:parseInt(employeeData[0].available_hours),
                hours_after_mov:parseInt(availableHours),
                hours_mov: parseInt(totalHours),
                carry_over_before_mov:parseInt(employeeData[0].carry_over),
                carry_over_after_mov:carryOverZero,
                carry_over_sick_time_before:parseInt(employeeData[0].carry_over_sick_time),
                carry_over_sick_time_after:parseInt(carryOverSickZero),
                observations: 'New H.R. PTO ' + absenceText,
                type_mov: 'New H.R. PTO',
                date_mov: moment().format('YYYY-MM-DD'),
                mov_increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                mov_yearly_increase_date:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD'),
                increase_date_before_mov:moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                yearly_increase_date_before_mov:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD')
            }

            if(absenceCode === 'LA' || absenceCode === 'FFCRA' || absenceCode === 'STD' || absenceCode === 'LTD' || absenceCode === 'MA' ) {

                


                balanceHoras({
                    available_hours: parseInt(availableHours) - parseInt(termDisabilityHours),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) + parseInt(totalHours),
                    total_requests: balanceHorasActual.total_requests + 1,
                    carry_over: carryOverZero,
                    sick_hours_limit: sickHoursZero,
                    carry_over_sick_time: carryOverSickZero,
                    increase_date: rangeEnd,
                    typeBalance: true,
                    uid : uid
                });
           // }
            }else {
                balanceHoras({
                    available_hours: parseInt(availableHours),
                    requested_hours: parseInt(balanceHorasActual.requested_hours) + parseInt(totalHours),
                    total_requests: balanceHorasActual.total_requests + 1,
                    carry_over: carryOverZero,
                    sick_hours_limit: sickHoursZero,
                    carry_over_sick_time: carryOverSickZero,
                    increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                    typeBalance: 'false',
                    uid : uid
                });
            }

            if(absenceCode === 'S') {
                let data = {
                    uid : uid,
                    hours_before_mov:parseInt(employeeData[0].sick_days_limit),
                    hours_after_mov:parseInt(sickHoursZero),
                    hours_mov: parseInt(totalHours),
                    carry_over_before_mov:parseInt(employeeData[0].carry_over),
                    carry_over_after_mov:carryOverZero,
                    carry_over_sick_time_before:parseInt(employeeData[0].carry_over_sick_time),
                    carry_over_sick_time_after:parseInt(carryOverSickZero),
                    observations: 'New PTO ' + absenceText,
                    type_mov: 'New PTO',
                    date_mov: moment().format('YYYY-MM-DD'),
                    mov_increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                    mov_yearly_increase_date:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD'),
                    increase_date_before_mov:moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                    yearly_increase_date_before_mov:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD')
                }
                axiosHr.post('/hour-movement-log', data)
                .then(respuesta => {
                    console.log(respuesta)
                })
                .catch(error => {
                console.log(error)
                });
            }
            else {

                if(absenceCode === 'LA' || absenceCode === 'FFCRA' || absenceCode === 'LTD' || absenceCode === 'STD' || absenceCode === 'MA') {
                    let data = {
                        uid : uid,
                        hours_before_mov:parseInt(employeeData[0].available_hours),
                        hours_after_mov:parseInt(availableHours) - parseInt(termDisabilityHours),
                        hours_mov: parseInt(termDisabilityHours),
                        carry_over_before_mov:parseInt(employeeData[0].carry_over),
                        carry_over_after_mov:carryOverZero,
                        carry_over_sick_time_before:parseInt(employeeData[0].carry_over_sick_time),
                        carry_over_sick_time_after:parseInt(carryOverSickZero),
                        observations: 'New PTO ' + absenceText,
                        type_mov: 'New PTO',
                        date_mov: moment().format('YYYY-MM-DD'),
                        mov_increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                        mov_yearly_increase_date:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD'),
                        increase_date_before_mov:rangeEnd,
                        yearly_increase_date_before_mov:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                // }else {
                //     axiosHr.post('/hour-movement-log', data)
                //     .then(respuesta => {
                //         console.log(respuesta)
                //     })
                //     .catch(error => {
                //     console.log(error)
                //     });
                }else {
                    let data = {
                        uid : uid,
                        hours_before_mov:parseInt(employeeData[0].available_hours),
                        hours_after_mov:parseInt(availableHours),
                        hours_mov: parseInt(totalHours),
                        carry_over_before_mov:parseInt(employeeData[0].carry_over),
                        carry_over_after_mov:carryOverZero,
                        carry_over_sick_time_before:parseInt(employeeData[0].carry_over_sick_time),
                        carry_over_sick_time_after:parseInt(carryOverSickZero),
                        observations: 'New PTO ' + absenceText,
                        type_mov: 'New PTO',
                        date_mov: moment().format('YYYY-MM-DD'),
                        mov_increase_date: moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                        mov_yearly_increase_date:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD'),
                        increase_date_before_mov:moment(employeeData[0].increase_date).format('YYYY-MM-DD'),
                        yearly_increase_date_before_mov:moment(employeeData[0].year_increase_date).format('YYYY-MM-DD')
                    }
                    axiosHr.post('/hour-movement-log', data)
                    .then(respuesta => {
                        console.log(respuesta)
                    })
                    .catch(error => {
                    console.log(error)
                    });
                }
                
            }
            
            //redirect
            history.push('/home');
        }
    })
      
      
    }

    return (
        <div className="row justify-content-center mt-5">
            {
            employeeData[0].company !== 'CSI'
                ?
                employeeData[0] !== undefined
                    ?
                    
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="text-center mb-4 font-weight-bold ">Add New Time Off Request</h2>
                                    <form onSubmit={submitNewPto}>
                                        <div className="form-group">
                                            <label>Employee Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Employee Name" 
    
                                                defaultValue={employeeData[0].employee_name}
                                                onChange={ e => saveEmployeeName(e.target.value) }
                                                required
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Department</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Department" 
                                                defaultValue={employeeData[0].department}
                                                onChange={ e => saveDepartment(e.target.value) }
                                                required
                                                disabled
                                            />
                                        </div>
    
                                        <div className="form-group">
                                            <label>Abscence Reason</label>
                                            <select className="custom-select"
                                            onChange={e=>handleChangeReasonCode(e.target.value)}
                                            required
                                            >
                                                <option value="">Select absence reason...</option>
                                                <option value="V">Vacation</option>
                                                <option value="PT">Personal Time</option>
                                                <option value="S">Sick</option>
                                                <option value="O">Other</option>
                                            </select>
                                        </div>
    
                                        <div className="form-group">
                                            <label>*Excused Absence</label>
                                            <select className="custom-select"
                                            onChange={e=>handleChangeReasonCode(e.target.value)}
                                            disabled
                                            ref = {specialCodeRef}
                                            >
                                                <option value="">Select reason...</option>
                                                <option value="LTD">Long Term Disability</option>
                                                <option value="STD">Short Term Disability</option>
                                                <option value="J">Jury Duty</option>
                                                <option value="B1">Bereavement 1</option>
                                                <option value="B2">Bereavement 2</option>
                                                <option value="B3">Bereavement 3</option>
                                                <option value="NB">New Baby Born</option>
                                                <option value="M">Marriage</option>
                                                <option value="MA">Maternity</option>
                                                <option value="VT">Vote</option>
                                            </select>
                                        </div>
    
                                        <div className="row container justify-content-center">
                                            <label><span className="font-weight-bold form-info">Indicate below the number of regularly scheduled hours you will be absent and the appropriate absence code for each day of absence requested.</span></label>
                                        </div>
    
                                        <div className="row container justify-content-between">
                                            
                                            
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Day Beginning:</label>
                                                    {/* <input 
                                                    type="date" 
                                                    className="form-control"
                                                    onChange={e=>saveDayBeginning(e.target.value)}
                                                    required
                                                    ref = {dayBeginingRef}
                                                    /> */}
                                                    <DatePicker
                                                    className="datePicker"
                                                    todayButton="Today"
                                                    selected={dayBegining}
                                                    onChange={date => saveDayBeginning(date)}
                                                    filterDate={isWeekday}
                                                    placeholderText="Select a weekday"
                                                    ref = {dayBeginingRef}
                                                // minDate={new Date()}
                                                    excludeDates={excludedDatesArray}
                                                    highlightDates = {excludedDatesArray}
                                                    required
                                                    >
                                                        <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                            {
                                                                backgroundColor : "crimson", 
                                                                borderRadius: ".5rem", 
                                                                color:"white",
                                                                textAlign: "center",
                                                                padding: "2px"
                                                            }
                                                            }>0</span></div>
                                                    </DatePicker>
    
                                                </div>
                                            </div>
    
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Day End:</label>
                                                    {/* <input 
                                                    type="date" 
                                                    className="form-control"
                                                    onChange={e=>saveDayEnd(e.target.value)}
                                                    required
                                                    ref = {dayEndRef}
                                                    /> */}
                                                    <DatePicker
                                                    className="datePicker"
                                                    todayButton="Today"
                                                    selected={dayEnd}
                                                    onChange={date => saveDayEnd(date)}
                                                    filterDate={isWeekday}
                                                    placeholderText="Select a weekday"
                                                    ref = {dayEndRef}
                                                    //minDate={new Date()}
                                                    excludeDates={excludedDatesArray}
                                                    highlightDates = {excludedDatesArray}
                                                    required
                                                    >
                                                        <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                            {
                                                                backgroundColor : "crimson", 
                                                                borderRadius: ".5rem", 
                                                                color:"white",
                                                                textAlign: "center",
                                                                padding: "2px"
                                                            }
                                                            }>0</span></div>
                                                    </DatePicker>
                                                </div>
                                            </div>
    
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Time Beginning:</label>
                                                    {/* <input 
                                                    type="time" 
                                                    className="form-control"
                                                    onChange={e=>saveTimeBeginning(e.target.value)}
                                                    ref={timeBeginningRef}
                                                    /> */}
                                                    <DatePicker
                                                    selected={timeBeginning}
                                                    onChange={date => saveTimeBeginning(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    minTime= {new Date().setHours(9,0,0,0)}
                                                    maxTime = {new Date().setHours(18,0,0,0)}
                                                    ref={timeBeginningRef}
                                                    />
                                                </div>
                                            </div>
    
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Time End:</label>
                                                    {/* <input 
                                                    type="time" 
                                                    className="form-control"
                                                    onChange={e=>saveTimeEnd(e.target.value)}
                                                    ref = {timeEndRef}
                                                    /> */}
                                                    <DatePicker
                                                    selected={timeEnd}
                                                    onChange={date => saveTimeEnd(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    minTime= {new Date().setHours(9,0,0,0)}
                                                    maxTime = {new Date().setHours(18,0,0,0)}
                                                    ref={timeEndRef}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
                                        { dateValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please select valid date range. </div> : null }
    
                                        { absenceCode === 'J' || absenceCode === 'B1'|| absenceCode === 'B2' || absenceCode === 'B3' || absenceCode === 'NB' || absenceCode === 'M' || absenceCode === 'MA'
                                            ? 
                                                <div>
                                                    <div className="font-weight-bold alert alert-warning text-center mt-4">
                                                    Please, schedule your excused absence by using {specialCodePlus} hours.
                                                    </div>
                                                    <div className="font-weight-bold alert alert-primary text-center mt-4">
                                                        Employees may use time from their PTO bank in hourly increments.
                                                    </div>
                                                </div>
                                            : 
                                                null 
                                        }
                                        <div className="form-group">
                                            <label>Total Number of Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={totalHours}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Carry Over Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={carryOver}
                                                disabled
                                                ref = {carryOverRef}
                                            />
                                        </div>
    
                                        <div className="form-group">
                                            <label>Available Sick Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={sickHours}
                                                disabled
                                                ref = {sickHoursRef}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label>Carry Over Sick Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={carryOverSickHours}
                                                disabled
                                                ref = {carryOverSickHoursRef}
                                            />
                                        </div>
    
    
                                        <div className="form-group">
                                            <label>Available Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={availableHours}
                                                disabled
                                            />
                                        </div>
    
                                        {
                                            availableHours < 0 || sickHours < 0
                                            ?
                                            <div className="form-group">
                                                <label>Explain reason for request if any part of absence is to be unpaid:</label>
                                                <textarea 
                                                    className="form-control"
                                                    value={reason}
                                                    //onChange={e=>saveReason(e.target.value)}
                                                    onChange = {handleChangeInput}
                                                    required
                                                    >
                                                </textarea>
                                            </div>
                                            :
                                            null
                                        }
                                        
    
                                        <div className="row container justify-content-center">
                                            <label>
                                                <p className="form-info">I am requesting the time off as shown above. I understand that any PTO/Vacation taken before it has accrued is an advance of wages.</p> 
                                                <p className="form-info">I understand that any wages advanced to me in the form of PTO/Vacation which has not accrued at the time of my
                                                termination from Administaff and/or Client Company will be deducted from the gross wages on my final paycheck which may reduce
                                                my final rate of pay below minimum wage and I authorize such deduction by Administaff from my final paycheck.</p>
    
                                                <p className="form-info">
                                                <span className="font-weight-bold">For California Employees Only:</span> I am requesting the time off as shown above. I understand that any PTO taken before it has accrued is an advance of wages and that I may be required to sign a Compensation Reduction/Deduction Authorization Form authorizing deduction from my pay of any amounts advanced.    
                                                </p>
                                            </label>
                                        </div>
    
                                        <div className="row container justify-content-between">
                                            
                                            
                                            <div className="col-md-8 col-sm-12">
                                                <label>Employee Signature:</label>
                                                <div className="form-group signatureContainer">
                                                   
                                                    <SignatureCanvas penColor = 'black' canvasProps={{
                                                       // width:300,
                                                       // height: 200,
                                                        className: 'signatureCanvas'
                                                    }} ref={ref => {sigPadEmployee = ref}} 
                                                    onEnd = {saveCanvasEmployee}
                                                    />
                                                    {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                                   
                                                </div>
                                                <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button>
                                            </div>
    
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Date:</label>
                                                    {/* <input 
                                                    type="date" 
                                                    className="form-control"
                                                    onChange={e=>saveDateSignEmployee(e.target.value)}
                                                    /> */}
                                                    <DatePicker
                                                    className="datePicker"
                                                    todayButton="Today"
                                                    selected={dateSignEmployee}
                                                    onChange={date => saveDateSignEmployee(date)}
                                                    filterDate={isWeekday}
                                                    placeholderText="Select a weekday"
                                                    minDate={new Date()}
                                                    excludeDates={excludedDatesArray}
                                                    required
                                                    disabled
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
    
                                        {/* <div className="row container justify-content-center">
                                            <label><span className="font-weight-bold">TO BE COMPLETED BY SUPERVISOR</span></label>
                                        </div> */}
    
                                        {/* <div className="row container justify-content-between">
                                            
                                            
                                            <div className="col-md-8 col-sm-12">
                                            <div className="form-group signatureContainer">
                                                    <label>Supervisor Signature:</label>
                                                    <SignatureCanvas penColor = 'black' canvasProps={{
                                                        width:300,
                                                        height: 200,
                                                        className: 'signatureCanvas'
                                                    }} ref={ref => {sigPadSupervisor = ref}} />
                                                    <button className="btn btn-secondary mt-2" onClick={saveCanvasSupervisor}>Save</button>
                                                    <button className="btn btn-secondary mt-2" onClick={clearSupervisor}>Clear X</button>
                                                </div>
                                            </div>
    
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Date:</label>
                                                    <input 
                                                    type="date" 
                                                    className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div> */}
    
                                        <div className="row container justify-content-center">
                                            <label className="form-info"><span className="font-weight-bold">Disclaimer: Client Company is solely obligated for paying all Client Company-initiated programs, policies and practices
                                            including, but not limited to, vacation, sick time and paid time off. </span></label>
                                        </div>
    
                                        <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4">Submit</button>
                                    </form>
    
                                    { error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please fill out all the required fields. </div> : null }
    
                                    { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
                    
                                </div>
                            </div>
                        </div>
                        :
                            null
                        
                :
                //CSI FORM
                employeeData[0] !== undefined
                    ?
                    
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="text-center mb-4 font-weight-bold ">Add New Time Off Request</h2>
                                    <form onSubmit={submitNewPto}>
                                        <div className="form-group">
                                            <label>Employee Name</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Employee Name" 
    
                                                defaultValue={employeeData[0].employee_name}
                                                onChange={ e => saveEmployeeName(e.target.value) }
                                                required
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Department</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder="Department" 
                                                defaultValue={employeeData[0].department}
                                                onChange={ e => saveDepartment(e.target.value) }
                                                required
                                                disabled
                                            />
                                        </div>
    
                                        <div className="form-group">
                                            <label>Abscence Reason</label>
                                            <select className="custom-select"
                                            onChange={e=>handleChangeReasonCode(e.target.value)}
                                            required
                                            >
                                                <option value="">Select absence reason...</option>
                                                <option value="V">Vacation</option>
                                                {/* <option value="PT">Personal Time</option> */}
                                                {/* <option value="S">Sick</option> */}
                                                <option value="O">Other</option>
                                            </select>
                                        </div>
    
                                        <div className="form-group">
                                            <label>*Excused Absence</label>
                                            <select className="custom-select"
                                            onChange={e=>handleChangeReasonCode(e.target.value)}
                                            disabled
                                            ref = {specialCodeRef}
                                            >
                                                <option value="">Select reason...</option>
                                                {/* <option value="LA">Leave of Absence</option>
                                                <option value="J">Jury Duty</option> */}
                                                <option value="MD">Medical Disability</option>
                                                <option value="B1">Bereavement 1</option>
                                                <option value="B2">Bereavement 2</option>
                                                <option value="B3">Bereavement 3</option>
                                                <option value="NBCSI">New Baby Born</option>
                                                <option value="M">Marriage</option>
                                                <option value="MA">Maternity</option>
                                                {/* <option value="VT">Vote</option> */}
                                            </select>
                                        </div>
    
                                        <div className="row container justify-content-center">
                                            <label><span className="font-weight-bold form-info">Indicate below the number of regularly scheduled hours you will be absent and the appropriate absence code for each day of absence requested.</span></label>
                                        </div>
    
                                        <div className="row container justify-content-between">
                                            
                                            
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Day Beginning:</label>
                                                    {/* <input 
                                                    type="date" 
                                                    className="form-control"
                                                    onChange={e=>saveDayBeginning(e.target.value)}
                                                    required
                                                    ref = {dayBeginingRef}
                                                    /> */}
                                                    <DatePicker
                                                    className="datePicker"
                                                    todayButton="Today"
                                                    selected={dayBegining}
                                                    onChange={date => saveDayBeginning(date)}
                                                    filterDate={isWeekday}
                                                    placeholderText="Select a weekday"
                                                    ref = {dayBeginingRef}
                                                // minDate={new Date()}
                                                    excludeDates={excludedDatesArray}
                                                    highlightDates = {excludedDatesArray}
                                                    required
                                                    >
                                                        <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                            {
                                                                backgroundColor : "crimson", 
                                                                borderRadius: ".5rem", 
                                                                color:"white",
                                                                textAlign: "center",
                                                                padding: "2px"
                                                            }
                                                            }>0</span></div>
                                                    </DatePicker>
    
                                                </div>
                                            </div>
    
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Day End:</label>
                                                    {/* <input 
                                                    type="date" 
                                                    className="form-control"
                                                    onChange={e=>saveDayEnd(e.target.value)}
                                                    required
                                                    ref = {dayEndRef}
                                                    /> */}
                                                    <DatePicker
                                                    className="datePicker"
                                                    todayButton="Today"
                                                    selected={dayEnd}
                                                    onChange={date => saveDayEnd(date)}
                                                    filterDate={isWeekday}
                                                    placeholderText="Select a weekday"
                                                    ref = {dayEndRef}
                                                    //minDate={new Date()}
                                                    excludeDates={excludedDatesArray}
                                                    highlightDates = {excludedDatesArray}
                                                    required
                                                    >
                                                        <div style={{color: "gray", margin :"5px"}}>Holidays are marked in red color <span style={
                                                            {
                                                                backgroundColor : "crimson", 
                                                                borderRadius: ".5rem", 
                                                                color:"white",
                                                                textAlign: "center",
                                                                padding: "2px"
                                                            }
                                                            }>0</span></div>
                                                    </DatePicker>
                                                </div>
                                            </div>
    
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Time Beginning:</label>
                                                    {/* <input 
                                                    type="time" 
                                                    className="form-control"
                                                    onChange={e=>saveTimeBeginning(e.target.value)}
                                                    ref={timeBeginningRef}
                                                    /> */}
                                                    <DatePicker
                                                    selected={timeBeginning}
                                                    onChange={date => saveTimeBeginning(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    minTime= {new Date().setHours(8,0,0,0)}
                                                    maxTime = {new Date().setHours(18,0,0,0)}
                                                    ref={timeBeginningRef}
                                                    />
                                                </div>
                                            </div>
    
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Time End:</label>
                                                    {/* <input 
                                                    type="time" 
                                                    className="form-control"
                                                    onChange={e=>saveTimeEnd(e.target.value)}
                                                    ref = {timeEndRef}
                                                    /> */}
                                                    <DatePicker
                                                    selected={timeEnd}
                                                    onChange={date => saveTimeEnd(date)}
                                                    showTimeSelect
                                                    showTimeSelectOnly
                                                    timeIntervals={60}
                                                    timeCaption="Time"
                                                    dateFormat="h:mm aa"
                                                    minTime= {new Date().setHours(8,0,0,0)}
                                                    maxTime = {new Date().setHours(18,0,0,0)}
                                                    ref={timeEndRef}
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
                                        { dateValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please select valid date range. </div> : null }
    
                                        { absenceCode === 'J' || absenceCode === 'B1'|| absenceCode === 'B2' || absenceCode === 'B3' || absenceCode === 'NB' || absenceCode === 'M' || absenceCode === 'MA'|| absenceCode === 'VT' || absenceCode === 'NBCSI'
                                            ? 
                                                <div>
                                                    <div className="font-weight-bold alert alert-warning text-center mt-4">
                                                    Please, schedule your excused absence by using {specialCodePlus} hours.
                                                    </div>
                                                    <div className="font-weight-bold alert alert-primary text-center mt-4">
                                                        Employees may use time from their PTO bank in hourly increments.
                                                    </div>
                                                </div>
                                            : 
                                                null 
                                        }
                                        <div className="form-group">
                                            <label>Total Number of Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={totalHours}
                                                disabled
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Carry Over Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={carryOver}
                                                disabled
                                                ref = {carryOverRef}
                                            />
                                        </div>
    
                                        <div className="form-group">
                                            <label>Available Sick Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={sickHours}
                                                disabled
                                                ref = {sickHoursRef}
                                            />
                                        </div>
    
    
                                        <div className="form-group">
                                            <label>Available Hours</label>
                                            <input 
                                                type="text" 
                                                className="form-control" 
                                                placeholder=""
                                                defaultValue={availableHours}
                                                disabled
                                            />
                                        </div>
    
                                        {
                                            availableHours < 0 || sickHours < 0
                                            ?
                                            <div className="form-group">
                                                <label>Explain reason for request if any part of absence will be unpaid or taken in advance:</label>
                                                <textarea 
                                                    className="form-control"
                                                    value={reason}
                                                    //onChange={e=>saveReason(e.target.value)}
                                                    onChange = {handleChangeInput}
                                                    required
                                                    >
                                                </textarea>
                                            </div>
                                            :
                                            null
                                        }
                                        
    
                                        <div className="row container justify-content-center">
                                            <label>
                                                <p className="form-info">I am requesting the time off as shown above. I understand that any PTO/Vacation taken before it has accrued is an advance of wages.</p> 
                                                <p className="form-info">I understand that any wages advanced to me in the form of PTO/Vacation which has not accrued at the time of my
                                                termination from Administaff and/or Client Company will be deducted from the gross wages on my final paycheck which may reduce
                                                my final rate of pay below minimum wage and I authorize such deduction by Administaff from my final paycheck.</p>
    
                                                {/* <p className="form-info">
                                                <span className="font-weight-bold">For California Employees Only:</span> I am requesting the time off as shown above. I understand that any PTO taken before it has accrued is an advance of wages and that I may be required to sign a Compensation Reduction/Deduction Authorization Form authorizing deduction from my pay of any amounts advanced.    
                                                </p> */}
                                            </label>
                                        </div>
    
                                        <div className="row container justify-content-between">
                                            
                                            
                                            <div className="col-md-8 col-sm-12">
                                                <label>Employee Signature:</label>
                                                <div className="form-group signatureContainer">
                                                    
                                                    <SignatureCanvas penColor = 'black' canvasProps={{
                                                      //  width:300,
                                                      //  height: 200,
                                                        className: 'signatureCanvas'
                                                    }} ref={ref => {sigPadEmployee = ref}} 
                                                    onEnd = {saveCanvasEmployee}
                                                    />
                                                    {/* <button className="btn btn-secondary mt-2" onClick={saveCanvasEmployee}>Save Signature</button> */}
                                                    
                                                </div>
                                                <button className="btn btn-secondary mt-2" onClick={clearEmployee}>Clear X</button>
                                            </div>
    
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Date:</label>
                                                    {/* <input 
                                                    type="date" 
                                                    className="form-control"
                                                    onChange={e=>saveDateSignEmployee(e.target.value)}
                                                    /> */}
                                                    <DatePicker
                                                    className="datePicker"
                                                    todayButton="Today"
                                                    selected={dateSignEmployee}
                                                    onChange={date => saveDateSignEmployee(date)}
                                                    filterDate={isWeekday}
                                                    placeholderText="Select a weekday"
                                                    minDate={new Date()}
                                                    excludeDates={excludedDatesArray}
                                                    required
                                                    disabled
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
    
                                        {/* <div className="row container justify-content-center">
                                            <label><span className="font-weight-bold">TO BE COMPLETED BY SUPERVISOR</span></label>
                                        </div> */}
    
                                        {/* <div className="row container justify-content-between">
                                            
                                            
                                            <div className="col-md-8 col-sm-12">
                                            <div className="form-group signatureContainer">
                                                    <label>Supervisor Signature:</label>
                                                    <SignatureCanvas penColor = 'black' canvasProps={{
                                                        width:300,
                                                        height: 200,
                                                        className: 'signatureCanvas'
                                                    }} ref={ref => {sigPadSupervisor = ref}} />
                                                    <button className="btn btn-secondary mt-2" onClick={saveCanvasSupervisor}>Save</button>
                                                    <button className="btn btn-secondary mt-2" onClick={clearSupervisor}>Clear X</button>
                                                </div>
                                            </div>
    
                                            <div className="col-md-4 col-sm-12">
                                                <div className="form-group">
                                                    <label>Date:</label>
                                                    <input 
                                                    type="date" 
                                                    className="form-control"
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div> */}
    
                                        {/* <div className="row container justify-content-center">
                                            <label className="form-info"><span className="font-weight-bold">Disclaimer: Client Company is solely obligated for paying all Client Company-initiated programs, policies and practices
                                            including, but not limited to, vacation, sick time and paid time off. </span></label>
                                        </div> */}
    
                                        <button type="submit" className="btn btn-primary font-weight-bold text-uppercase d-block w-100 mb-4">Submit</button>
                                    </form>
    
                                    { error ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Please fill out all the required fields. </div> : null }
    
                                    { hourValidationError ? <div className="font-weight-bold alert alert-danger text-center mt-4">ERROR: Sorry there are no hours available. </div> : null }
                    
                                </div>
                            </div>
                        </div>
                        :
                            null
            }
                
        </div>
    );
};

export default NewPtoHr;